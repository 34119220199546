import React, { useEffect, useState } from "react";
import {
  UpdatePasswordFormTypes,
  UpdatePasswordPageProps,
  useTranslate,
  useUpdatePassword,
  useUpdate,
} from "@pankod/refine-core";
import { useParams } from "@pankod/refine-react-router-v6";
import axios from "axios";
import { Button, Stack, TextField } from "@mui/material";

type DivPropsType = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;
type FormPropsType = React.DetailedHTMLProps<
  React.FormHTMLAttributes<HTMLFormElement>,
  HTMLFormElement
>;

type UpdatePasswordProps = UpdatePasswordPageProps<
  DivPropsType,
  DivPropsType,
  FormPropsType
>;

export const UpdatePasswordPage: React.FC<UpdatePasswordProps> = ({
  wrapperProps,
  contentProps,
  renderContent,
  formProps,
}) => {
  const translate = useTranslate();

  const { mutate } = useUpdate();

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const { token } = useParams()

  useEffect(() => {
    console.log(token)
  }, [token])
  

  const content = (
    <div style={{display:'flex', justifyContent:'center'}} {...contentProps}>
      <div /*className="outer-outer-form"*/ style={{maxWidth:850, minWidth:300, marginTop:50}}>
      <div className="outer-auth-form">
      <h1 className="auth-title">
        {translate("pages.updatePassword.title", "Update Password")}
      </h1>
      <hr />
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          const reset = await axios.post(process.env.REACT_APP_API+'/accounts/reset-password', {token, password:newPassword, confirmPassword})
          alert(reset)
        }}
        {...formProps}
      >
        <div style={{width:'100%'}}>
          <Stack spacing={{ xs: 1, sm: 2 }}>
          <TextField
            name="password"
            label={translate("pages.updatePassword.fields.password", "New Password")}
            type="password"
            required
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <TextField
            label={translate(
              "pages.updatePassword.fields.confirmPassword",
              "Confirm New Password"
            )}
            name="confirmPassword"
            type="password"
            required
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <Button
            type="submit"
            disabled={confirmPassword !== newPassword}
            variant="contained"
          >
            {translate("pages.updatePassword.buttons.submit", "Update")}
          </Button>
          </Stack>
        </div>
      </form>
      </div>
      </div>
    </div>
  );

  return (
    <div className="background-auth-page" {...wrapperProps}>
      {renderContent ? renderContent(content) : content}
    </div>
  );
};
