import { ContactMail } from "@mui/icons-material"
import { Button, Container, Grid, TextField } from "@mui/material"
import axios from "axios"
import { PublicFooter } from "components/PublicFooter"
import { PublicMenu } from "components/PublicMenu"
import { useEffect, useState } from "react"

export const ContactUsPage = () => {
    
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [formSent, setFormSent] = useState(false)

    const handleSubmit = async() => {
        try {
            axios.post(process.env.REACT_APP_API+"/notifications/contact_us", {name, email, message})
            setFormSent(true)

        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
        <Grid container style={{backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.20), rgba(1, 1, 1, 0.20)),url("/images/public_banner.jpg")', backgroundPosition:'center', backgroundSize:'cover', padding:20, paddingBottom:100}}>
            <PublicMenu />
            <Grid item xs={12} style={{textAlign:'center'}}>
                <p style={{color:'white', fontFamily:'Satisfy, cursive', fontSize:'2em', marginBottom:0}}>
                    <ContactMail fontSize="large" />
                    <br />
                    Contact Us
                </p>
            </Grid>
        </Grid>
        <Container style={{textAlign:'center'}}>
            <h3>Contact Us</h3>
            {!formSent ?
            <>
                <TextField 
                    id="filled-basic" 
                    name="name" 
                    label="Name" 
                    variant="filled" 
                    style={{margin:10}} 
                    onChange={(e) => setName(e.target.value)}
                />
                <br />
                <TextField 
                    id="filled-basic" 
                    name="email" 
                    label="Email" 
                    variant="filled" 
                    style={{margin:10}} 
                    onChange={(e) => setEmail(e.target.value)}
                />
                <br />
                <TextField 
                    id="filled-basic" 
                    label="Message" 
                    variant="filled" 
                    name="message"
                    multiline
                    rows={4} 
                    style={{margin:10}}
                    onChange={(e) => setMessage(e.target.value)}
                />
                <br />
                <Button
                    variant="contained"
                    style={{margin:10}}
                    onClick={handleSubmit}
                >Send Message</Button>
            </>
            :
            <h2>Your message has been sent</h2>}
        </Container>
        <PublicFooter />
        </>
    )
}