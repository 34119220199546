import React, { useState, useEffect } from "react";
import {
    useDataGrid,
    DataGrid,
    GridColumns,
    EditButton,
    ShowButton,
    List,
    DateField,
    CreateButton,
    Grid,
    Button,
} from "@pankod/refine-mui";
import { GridView, ListAlt } from "@mui/icons-material";
import { useMany, HttpError } from "@pankod/refine-core";
import { LoggedInUser } from "utils/auth.management";
import { ProjectPreview } from "components/projectPreviews";
import { Link } from '@pankod/refine-react-router-v6'

export const ProjectList = () => {
    const { dataGridProps } = useDataGrid();
    const [viewTableLayout, setViewTableLayout] = useState(false)
    const [allCategories, setAllCategories] = useState<any>([])

    const { data, isLoading, isError } = useMany<HttpError>({
        resource: "projects",
        ids: [],
    });

    const projects = data?.data ?? [];

    useEffect(() => {
      let localData:any[] = []
      projects?.filter(item => !localData.includes(item.category) && localData.push(item.category))
      setAllCategories(localData)
    }, [projects])

    const columns = React.useMemo<GridColumns<any>>(
        () => [
            {
                field: "title",
                headerName: "Title",
                minWidth: 200,
            },
            {
                field: "location",
                headerName: "Location",
                minWidth: 200,
            },
            {
                field: "category",
                headerName: "Category",
                minWidth: 200,
            },
            {
                field: "campaign_photo",
                headerName: "Campaign Photo",
                minWidth: 200,
                renderCell: function render({ value }) {
                    return <img src={value} style={{height:50}} />;
                },
            },
            {
                field: "project_video",
                headerName: "Project Video",
                minWidth: 200,
            },
            {
                field: "currency",
                headerName: "Currency",
                minWidth: 200,
            },
            {
                field: "funding_start_date",
                headerName: "Funding Start Date",
                minWidth: 250,
                renderCell: function render({ value }) {
                    return <DateField value={value} />;
                },
            },
            {
                field: "funding_target_date",
                headerName: "Funding Target Date",
                minWidth: 250,
                renderCell: function render({ value }) {
                    return <DateField value={value} />;
                },
            },
            {
                field: "target_funding_goal",
                headerName: "Target Funding Goal",
                minWidth: 200,
            },
            {
                field: "story",
                headerName: "Story",
                minWidth: 200,
            },
            {
                field: "subcategory",
                headerName: "Subcategory",
                minWidth: 200,
            },
            {
                field: "created",
                headerName: "Created",
                minWidth: 250,
                renderCell: function render({ value }) {
                    return <DateField value={value} />;
                },
            },
            {
                field: "id",
                headerName: "Id",
                minWidth: 50,
            },
            {
                field: "actions",
                headerName: "Actions",
                renderCell: function render({ row }) {
                    return (
                        <>
                            {LoggedInUser().id === row.founder && <EditButton hideText recordItemId={row.id} />}
                            <ShowButton hideText recordItemId={row.id} />
                        </>
                    );
                },
                align: "center",
                headerAlign: "center",
                minWidth: 80,
            },
        ],
        [],
    );

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (isError) {
        return <div>Something went wrong!</div>;
    }

    return (
        <List
            title="My Projects"
            headerButtons={({ defaultButtons }) => (
                <>
                    <CreateButton>Start Project</CreateButton>
                    <Button onClick={() => {
                        if(viewTableLayout){
                            setViewTableLayout(false)
                        } else {
                            setViewTableLayout(true)
                        }
                    }}>
                        {
                            viewTableLayout ?
                            <GridView />
                            :
                            <ListAlt />

                        }
                    </Button>
                </>
            )}
        >
            {
                viewTableLayout ?
                <DataGrid {...dataGridProps} columns={columns} autoHeight />
                :
                <>
                {allCategories.map((_x:any, index2:any) => 
                    <>
                    <h1 style={{textAlign:'center'}}>{_x}</h1>
                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} style={{maxWidth:700, margin:'auto'}}>

                        {projects.map((_, index) => (

                            _x === _.category &&

                            <Grid item xs={2} sm={4} md={4} key={index}>
                                <ProjectPreview _={_} />
                            </Grid>

                        ))}

                    </Grid>

                    </>
                )}
                </>
            }
            {projects.length === 0 && <p style={{textAlign:'center'}}>To proceed it is best to FIRST <Link to="/projects/create">Start a Project</Link>, then create your CARE TEAM, and add Projects to your CARE TEAM.</p>}
        </List>
    );
};
