import React, { useState } from "react";
import { LayoutProps, useUpdate, useOne, HttpError } from "@pankod/refine-core";
import { Header as DefaultHeader, Box, Edit, Button, TextField, Card, CardMedia, CardContent, Typography, Stack, Modal } from "@pankod/refine-mui";
import { CustomSider } from 'components/CustomSider';
import { LoggedInUser } from 'utils/auth.management';
import { SaveAsOutlined } from "@mui/icons-material";
import ImageUploader from "utils/image.uploader";
import { style } from "utils/styles.custom";

interface IAccount {
    id: number;
    firstName: string;
    lastName: string;
    avatar: string;
    title: string;
    email: string;
    role: string;
    isVerified: boolean;
}

export const UserAccountPage: React.FC<LayoutProps> = ({
    Header,
    Footer,
    OffLayoutArea,
    children,
}) =>  {
    const HeaderToRender = Header ?? DefaultHeader;
    const { mutate } = useUpdate();

    const onSubmit = () => {
        mutate({
            resource: "accounts",
            id: LoggedInUser().id,
            values: { firstName, lastName, avatar, title, email },
            mutationMode: "optimistic",
        });
        //localStorage.removeItem('avatar')
        //localStorage update user
    }

    const { data, isLoading, isError } = useOne<IAccount, HttpError>({
        resource: "accounts",
        id:LoggedInUser().id,
    });

    const account = data?.data;
    const [ url, setUrl ] = useState(account?.avatar);
    let avatar = url
    const [firstName, setFName] = useState(account?.firstName)
    const [lastName, setLastName] = useState(account?.lastName)
    const [title, setTitle] = useState(account?.title)
    const [email, setEmail] = useState(account?.email)
    const [deleteModal, setDeleteModal] = useState(false)

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (isError) {
        return <div>Something went wrong!</div>;
    }

    return (
        <Box display="flex" flexDirection="row">
            <CustomSider />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    minHeight: "100vh",
                }}
            >
                <HeaderToRender />
                <Box
                    component="main"
                    sx={{
                        p: { xs: 1, md: 2, lg: 3 },
                        flexGrow: 1,
                        bgcolor: "background.default",
                    }}
                >
                    <Edit
                        resource="accounts" 
                        recordItemId={LoggedInUser().id}
                        footerButtons={({ defaultButtons }) => (
                            <>
                                <Button 
                                    onClick={onSubmit}
                                    variant="contained"
                                ><SaveAsOutlined /> Update</Button>
                            </>
                        )}
                    >
                        <Card 
                            style={{maxWidth:500, margin:'auto'}}
                        >
                            <CardMedia
                                component="img"
                                image={account?.avatar}
                                alt="avatar"
                            />

                            <CardContent>

                                <ImageUploader url={url} setUrl={setUrl} label={'Avatar'} name={'avatar'} />

                                <Typography variant="body2" color="text.secondary">
                                    <Stack>
                                    <TextField
                                        label='First Name'
                                        margin="normal"
                                        variant="outlined"
                                        value={firstName}
                                        onChange={(e) => setFName(e.target.value)}
                                        name='firstName'
                                        defaultValue={account?.firstName}
                                    />
                                    <TextField
                                        label='Last Name'
                                        margin="normal"
                                        variant="outlined"
                                        defaultValue={account?.lastName}
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                        name='lastName'
                                    />
                                    <TextField
                                        label='Title'
                                        margin="normal"
                                        variant="outlined"
                                        defaultValue={account?.title}
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                        name='title'
                                    />
                                    <TextField
                                        label='Email'
                                        margin="normal"
                                        variant="outlined"
                                        defaultValue={account?.email}
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        name='email'
                                    />
                                    
                                    </Stack>
                                </Typography>
                                
                                <span>id: {account?.id}</span>
                            </CardContent>

                        </Card>
                    </Edit>
                    <Modal
                        open={deleteModal}
                        onClose={() => setDeleteModal(false)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                            Are you sure you want to delete your account?
                            </Typography>
                            <Button
                                onClick={() => setDeleteModal(false)}>No, keep my account</Button>
                            <Button
                                onClick={() => {
                                    mutate({
                                        resource: "accounts",
                                        id: LoggedInUser().id,
                                        values: { email:"_"+account?.email+"_" },
                                        mutationMode: "optimistic",
                                    });
                                    localStorage.removeItem("refine-auth");
                                    localStorage.removeItem('user')
                                    window.location.replace("/")
                                }}
                            >I am sure I want to delete my account</Button>
                        </Box>
                    </Modal>
                    <Button 
                        fullWidth
                        variant="contained"
                        onClick={() => {
                            setDeleteModal(true)
                        }}
                    >Delete Account</Button>
                </Box>
                {Footer && <Footer />}
            </Box>
            {OffLayoutArea && <OffLayoutArea />}
        </Box>
    );
};