import { Email, Person } from "@mui/icons-material";
import { useShow, useOne, useMany, HttpError } from "@pankod/refine-core";
import {
    Show,
    Typography,
    Stack,
    TextFieldComponent as TextField,
    TextField as TextField2, 
    Tabs,
    Tab,
    Container,
    Box,
    Grid,
    Card,
    CardContent,
    EditButton,
    DeleteButton,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Button,
} from "@pankod/refine-mui";
import { CommentBox } from "components/commentBox";
import { useState, useEffect } from "react";
import { TeamAdd } from "./components/Add";
import { LoggedInUser } from "utils/auth.management";
import { ProjectPreview } from "components/projectPreviews";
import axios from "axios";
import { TeamAddMember } from "./components/AddMember";
import { TeamAddProject } from "./components/AddProject";

export const MembersList = (data:any) => {

    const { item, edit_status } = data

    const style1 = {padding:5, border:'3px solid red'}

    return (
        <Card style={ edit_status ? style1 : {padding:0, border:'none'}}>
            {item.avatar ?
            <img src={item.avatar} alt="avatar" style={{width:'100%'}} />
            :
            item.campaign_photo ?
            <img src={item.campaign_photo} alt="avatar" style={{width:'100%'}} />
            :
            <Container style={{paddingTop:30}}>
                <Person fontSize="large" style={{margin:'auto', width:'100%'}} />
            </Container>
            }
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {item.firstName || item.title}
                </Typography>
            </CardContent>
        </Card>
    )
}

export const TeamShow = () => {
    const { queryResult } = useShow();
    const { data, isLoading } = queryResult;
    const [tabValue, setTabValue] = useState('one')
    const record = data?.data;
    const [teamsProjects, setTeamProjects] = useState<any>([])
    const [memberIds, setMemberIds] = useState<any>([])
    const [whatToShare, setWhatToShare] = useState(false)

    const { data: projectData, isLoading: projectIsLoading } = useOne({
        resource: "projects",
        id: record?.project || "",
        queryOptions: {
            enabled: !!record,
        },
    });

    const { data: allAccounts, isLoading:loadingAccounts, isError } = useMany<HttpError>({
        resource: "accounts",
        ids: [2, 3],
    });

    const { data: allProjects, isLoading:loadingProjects, isError:proejctError } = useMany<HttpError>({
        resource: "projects",
        ids: [2, 3],
    });

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue)
    }

    useEffect(() => {
      let localTeamsProjects:any[] = []
      localTeamsProjects.push(projectData?.data?.id)
      allProjects?.data?.filter(item => record?.projects?.includes(item.id) && localTeamsProjects.push(item.id))
        let uniqueChars = localTeamsProjects.filter((c, index) => {
            return localTeamsProjects.indexOf(c) === index;
        });
    
    
        let localProjects:any[] = []
        allProjects?.data?.filter(item => uniqueChars.includes(item.id) && localProjects.push(item))
        setTeamProjects(localProjects);
    }, [allProjects, tabValue])

    useEffect(() => {
        let localIds = []
        record?.members?.filter((item:any) => localIds.push(item))
        localIds.push(record?.founder)
        setMemberIds(localIds.reduce(function(a,b){if(a.indexOf(b)<0)a.push(b);return a;},[]))
    }, [record])
    
    
    let founder_info = allAccounts?.data.filter(item => item. id === record?.founder)[0]

    const [share_project, setShareProject] = useState<any>([])
    const [emailToInvite, setEmailToInvite] = useState('')

    
    return (
        <Show 
            title={<Typography variant="h5">My Care Teams</Typography>}
            isLoading={isLoading}
            canDelete={LoggedInUser().id === record?.founder}
            canEdit={LoggedInUser().id === record?.founder}
            headerButtons={({ defaultButtons }) => (
                <>
                    <EditButton />
                    <DeleteButton />
                </>
            )}
        >
            <div style={{display:'flex', alignItems:'center'}}>
                <img
                    style={{ maxWidth: 50, height:50, width: "100%", borderRadius:5, marginRight:10 }}
                    src={record?.image}
                />
                <h1>{record?.name}</h1>
            </div>
            <div style={{marginLeft:10, display:'flex', alignItems:'center'}}>
                <Person /> {founder_info?.firstName+ " "+founder_info?.lastName}
            </div>
            <Box sx={{ maxWidth: { xs: 320, sm: '100%' }, bgcolor: 'background.paper' }}>
                <Tabs
                value={tabValue}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="scrollable force tabs example"
                >
                    <Tab
                        value="one"
                        label="Members"
                    />
                    <Tab value="five" label="Add a member" />
                    <Tab value="two" label="Projects" />
                    <Tab value="three" label="Add a project" />
                    <Tab value="four" label="Coordination" />
                </Tabs>
                <Stack gap={1}>
                    {tabValue === 'one' &&
                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                            {allAccounts?.data?.map((item:any, index) =>
                                memberIds?.includes(item.id) &&
                                <Grid item xs={1} sm={3} md={2} key={index}>
                                    <MembersList item={item} />
                                </Grid>
                            )}

                        </Grid>
                    }
                    {tabValue === 'two' &&
                    <>   
                    <Container>
                    <Button style={{color:'white', margin:20}} size="small" variant="contained" onClick={() => {
                        if(whatToShare){
                            setWhatToShare(false)
                        } else {
                            setWhatToShare(true)
                        }
                    }}>{whatToShare ? "Don't email invites for a project" : "Send email invites for a project"}</Button>
                    {whatToShare &&
                    <FormControl fullWidth sx={{ m: 1 }} style={{margin:0, marginBottom:10}}>
                        <InputLabel id="demo-simple-select-label">What project do you want to share?</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="What project do you want to share?"
                            
                            style={{width:300}}
                        >
                            {teamsProjects?.map((item:any, index:any) =>
                                <MenuItem onClick={() => setShareProject(item)} key={index} value={item.title}>{item.title}</MenuItem>
                            )}
                        </Select>
                        <TextField2 
                            label="Who do you want to share it with? - Email Invitation" 
                            onChange={(e:any) => setEmailToInvite(e.target.value)}
                            value={emailToInvite}
                            style={{marginTop:20, marginBottom:20}}
                        />
                        <Button 
                            onClick={() => {
                                axios.post(`${process.env.REACT_APP_API}/notifications/project_invitation`, {to:emailToInvite, founder:LoggedInUser().firstName, project_name:share_project?.title, project_id:share_project?.id})
                                setEmailToInvite('')
                                alert('Invitation has been sent')
                            }} 
                            variant="contained" 
                            startIcon={<Email />}
                        >
                            <span>Send {share_project?.title} Invitation</span>
                        </Button>
                    </FormControl>
                    }
                    </Container>
                        {projectIsLoading ? (
                            <>Loading...</>
                        ) : (
                            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                {teamsProjects?.map((item:any, index:any) =>
                                    <Grid item xs={2} sm={4} md={4} key={index}>
                                        <ProjectPreview _={item} />
                                    </Grid>
                                )}
                            </Grid>
                        )}
                    </>
                    }
                    {tabValue === 'three' &&
                    <>   
                        {projectIsLoading ? (
                            <>Loading...</>
                        ) : (
                            <>
                            <TeamAddProject team_id={record?._id} projects={record?.projects} />
                            </>
                        )}
                    </>
                    }
                    {tabValue === 'four' &&
                    <>   
                        {projectIsLoading ? (
                            <>Loading...</>
                        ) : (
                            <>
                            {<CommentBox details={{resource:'team', record, resource_id:record?._id}} />}
                            </>
                        )}
                    </>
                    }
                    {tabValue === 'five' &&
                    <>
                        {projectIsLoading ? (
                            <>Loading...</>
                        ) : (
                            <>
                            <TeamAddMember team_id={record?._id} members={record?.members} founder_name={LoggedInUser().name} team_name={record?.name} />
                            </>
                        )}
                    </>
                    }
                </Stack>

                </Box>
                
        </Show>
    );
};
