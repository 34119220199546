import React, { useState } from "react";
import {
  LoginPageProps,
  LoginFormTypes,
  useRouterContext,
  useLogin,
  useTranslate,
} from "@pankod/refine-core";
import { Button, TextField } from "@mui/material";
import { PasswordBlocker } from "components/passwordBlock";

type DivPropsType = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;
type FormPropsType = React.DetailedHTMLProps<
  React.FormHTMLAttributes<HTMLFormElement>,
  HTMLFormElement
>;

type LoginProps = LoginPageProps<DivPropsType, DivPropsType, FormPropsType>;

export const LoginPage: React.FC<LoginProps> = ({
  providers,
  registerLink,
  forgotPasswordLink,
  rememberMe,
  contentProps,
  wrapperProps,
  renderContent,
  formProps,
}) => {
  const { Link } = useRouterContext();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(false);

  const translate = useTranslate();

  const { mutate: login } = useLogin<LoginFormTypes>();

  const renderLink = (link: React.ReactNode, text?: string) => {
    if (link) {
      if (typeof link === "string") {
        return <Link to={link}>{text}</Link>;
      }
      return link;
    }
    return null;
  };

  const renderProviders = () => {
    if (providers) {
      return providers.map((provider) => (
        <div
          key={provider.name}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "1rem",
          }}
        >
          <button
            onClick={() =>
              login({
                providerName: provider.name,
              })
            }
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {provider?.icon}
            {provider.label ?? <label>{provider.label}</label>}
          </button>
        </div>
      ));
    }
    return null;
  };

  const content = (
    <div className="auth-form" {...contentProps}>
    <div className="outer-outer-form">
    <div className="outer-auth-form">
      <h1 className="auth-title">
        {translate("pages.login.title", "Sign in to your account")}
      </h1>
      {renderProviders()}
      <hr />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          login({ email, password, remember });
        }}
        {...formProps}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: 25,
          }}
        >
          <TextField 
            id="outlined-basic" 
            label="Email" 
            variant="outlined" 
            name="email"
            type="email"
            autoCorrect="off"
            spellCheck={false}
            autoCapitalize="off"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ width: 1 }}
            style={{margin:'0px 5px 10px'}}
          />
          <TextField 
            id="outlined-basic" 
            label="Password" 
            variant="outlined" 
            name="password"
            type="password"
            autoCorrect="off"
            spellCheck={false}
            autoCapitalize="off"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{ width: 1 }}
            style={{margin:'0px 5px 10px'}}
          />
          {rememberMe ?? (
            <>
              <label>
                {translate("pages.login.buttons.rememberMe", "Remember me")}
                <input
                  name="remember"
                  type="checkbox"
                  size={20}
                  checked={remember}
                  value={remember.toString()}
                  onChange={() => {
                    setRemember(!remember);
                  }}
                />
              </label>
            </>
          )}
          <br />
          {forgotPasswordLink ??
            renderLink(
              "/forgot-password",
              translate(
                "pages.login.buttons.forgotPassword",
                "Forgot password?"
              )
            )}
          <div style={{margin:10}}>
            <Button sx={{ width: 1 }} type="submit" variant="contained">{translate("pages.login.signin", "Sign in")}</Button>          
          </div>
          {registerLink ?? (
            <span>
              {translate(
                "pages.login.buttons.noAccount",
                "Don’t have an account?"
              )}{" "}
              {renderLink(
                "/register",
                translate("pages.login.register", "Register")
              )}
            </span>
          )}
        </div>
      </form>
      </div>
      </div>
    </div>
  );

  return (
    <>
    <PasswordBlocker />
    <div className="background-auth-page" {...wrapperProps}>
      {renderContent ? renderContent(content) : content}
    </div>
    </>
  );
};
