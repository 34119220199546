import { AuthProvider } from "@pankod/refine-core";
import axios from 'axios';

export const TOKEN_KEY = "refine-auth";

export const authProvider: AuthProvider = {
  login: async ({ username, email, password }) => {
    try {
      const results = await axios.post(process.env.REACT_APP_API+"/accounts/authenticate", {email, password})
      localStorage.setItem('user', JSON.stringify(results.data))
      localStorage.setItem(TOKEN_KEY, username);
      let startedProject = localStorage.getItem('project');
      if(startedProject){
        window.location.replace("/projects/create")
      } else {
        window.location.replace("/teams")
      }
      return Promise.resolve();
    } catch (error) {
      console.log(error)
      return Promise.reject(new Error("Your account is not verified, or your username / password is wrong."));
    }
  },
  logout: () => {
    let confirm = window.confirm
    if (confirm("Are you sure you want to log out?")) {
      localStorage.removeItem(TOKEN_KEY);
      localStorage.removeItem('user')
    } else {
      return Promise.reject();
    }
    return Promise.resolve();
  },
  register: async ({ email, title, firstName, lastName, acceptTerms, role, password, confirmPassword,  street, unit_apt, city, state, zip_code, cell_phone, team_id }) => {
    // You can handle the register process according to your needs.
    try {
      await axios.post(process.env.REACT_APP_API+'/accounts/register', {email, password, firstName, lastName, acceptTerms, confirmPassword, street, unit_apt, city, state, zip_code, cell_phone, team_id})
      // If the process is successful.
      return Promise.resolve();
    } catch (error:any) {
      console.log(error.response.data.message)
      let newErrMsg = () => {
        if(error.response.data.message){
          if(error.response.data.message.includes('[ref:password]')){
            return error.response.data.message.replace('[ref:password]', 'the same as password')
          } else {
            return error.response.data.message
          }
        } else {
          return error.response.data
        }

      }
      return Promise.reject(new Error(newErrMsg()));
    }

  },
  checkError: () => Promise.resolve(),
  checkAuth: () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      return Promise.resolve();
    }

    return Promise.reject();
  },
  getPermissions: () => Promise.resolve(),
  getUserIdentity: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) {
      return Promise.reject();
    }

    return Promise.resolve({
      id: 1,
    });
  },
};

