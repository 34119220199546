import { TextField } from '@mui/material';

const ImageUploader = ({label, name, url, setUrl}) => {
    
    const uploadImage = async(image) => {
        const data = new FormData()
        data.append("file", image)
        data.append("upload_preset", "zf0j1xe9")
        data.append("cloud_name","dzpuzxbdt")

        fetch("https://api.cloudinary.com/v1_1/dzpuzxbdt/image/upload",{method:"post",body: data})
        .then(resp => resp.json())
        .then(data => {
            setUrl(data.url)
        })
        .catch(err => console.log(err))
    }
            
            return (
                <>
                    <TextField
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="file"
                        label={label}
                        name={name}
                        onChange= {(e)=> uploadImage(e.target.files[0])}
                    />
                    
                    {!url &&
                        <i style={{padding:5}}>Uploaded image will be displayed here</i>
                    }
                    <div style={{display:'flex', justifyContent:'center'}}>
                        <img src={url} style={{width:250, padding:20,}} />
                    </div>

                </>)
}

export default ImageUploader;