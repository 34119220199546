import { Button, Container, TextField, Typography } from "@mui/material"
import { useState } from 'react'

export const PasswordBlocker = () => {
const [password, setPassword] = useState('')

const passwordEntered = localStorage.getItem('WEBSITE_CODE');

const handleSetPassword = () => {
    localStorage.setItem('WEBSITE_CODE', password);
    window.location.reload()
}

 return (
    <>
    {passwordEntered !== 'dougsokwithit' &&
        <div style={{zIndex:100000, top:0, left:0, backgroundColor:'white', position:'fixed', width:'100%', height:'100vh'}}>
            <Container style={{textAlign:'center', marginTop:30}}>
                <Typography variant="h5">Password required</Typography>
                <TextField style={{width:'100%', margin:10}} onChange={(e) => setPassword(e.target.value)} id="outlined-basic" label="Website Password" variant="outlined" />
                <Button fullWidth variant="contained" onClick={handleSetPassword}>Submit Password</Button>
            </Container>
        </div>
    }
    </>
 )
}