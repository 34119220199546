import { Person } from "@mui/icons-material";
import { useOne } from "@pankod/refine-core";
import {
    Typography,
    Container,
    
    Card,
    CardContent,
} from "@pankod/refine-mui";

export const ProjectList = (data:any) => {

    const { id, edit_status } = data

    const { data: projectMain } = useOne({
        resource: "projects",
        id,
    });

    let project = projectMain?.data ?? {}

    const style1 = {padding:5, border:'3px solid red'}

    return (
        <Card style={ edit_status ? style1 : {padding:0, border:'none'}}>
            {project.avatar ?
            <img src={project.avatar} alt="avatar" style={{width:'100%'}} />
            :
            project.campaign_photo ?
            <img src={project.campaign_photo} alt="avatar" style={{width:'100%'}} />
            :
            <Container style={{paddingTop:30}}>
                <Person fontSize="large" style={{margin:'auto', width:'100%'}} />
            </Container>
            }
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {project.firstName || project.title}
                </Typography>
            </CardContent>
        </Card>
    )
}