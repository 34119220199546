import { Favorite } from "@mui/icons-material";
import { Grid, Button, Stack, Card, CardMedia, CardContent, Typography, Container } from "@mui/material";
import { PublicFooter } from "components/PublicFooter";
import { PublicMenu } from "components/PublicMenu";

export default function WhatWeDo(){

    const handleStartProject = () => {
        window.location.replace('/start/project')
    }

    let data = [
        {image:"/images/we-do-connect.jpg", title:"Connect:", body:"When a loved one’s health changes, or they experience a medical procedure, sometimes the consequences can be challenging, and you may need to act quickly. A life changing event, or a simple procedure that require medical equipment or services can be a daunting task when you’re facing it alone. You may need help. Let HelpGrandma help you connect with friends or family to build your CARE TEAM."},
        {image:"/images/we-do-collaborate.jpg", title:"Collaborate:", body:"Once you’ve built your Care Team you can move quickly. HelpGrandma allows family and friends to work together. Each Care Team member can help explore products and services and contribute to solving your loved one’s immediate needs. You don’t have to face it alone. In addition, your Care Team can continue to collaborate foradditional as well as ongoing needs."},
        {image:"/images/we-do-funds.jpg", title:"Fund:", body:"Once decisions have been made, set your funding goal for each item or service needed. Begin funding! Every Care team member has the ability to fund at whatever level they choose, or simply help with collaborative efforts. As your loved one’s needs may change, use HelpGrandma for additional services or funding as they may require."},
 ]

    return (
        <>
            <Grid container style={{backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.20), rgba(1, 1, 1, 0.20)),url("/images/public_banner.jpg")', backgroundPosition:'center', backgroundSize:'cover', padding:20, paddingBottom:100}}>
                <PublicMenu />
                <Grid item xs={12} style={{textAlign:'center'}}>
                    <p style={{color:'white', fontFamily:'Satisfy, cursive', fontSize:'2em', marginBottom:0}}>
                        <Favorite fontSize="4em" />
                        <br />
                        What We Do
                    </p>
                </Grid>
            </Grid>
            <Container style={{padding:50}}>
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 1, sm: 2, md: 4 }}
                >
                    {data.map((item, index) =>
                    <Card sx={{ maxWidth: 345 }}>
                        <CardMedia
                            sx={{ height: 140 }}
                            image={item.image}
                            title={item.title}
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                            {item.title}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                            {item.body}
                            </Typography>
                        </CardContent>
                    </Card>
                    )}
                </Stack>
            </Container>
            <Container style={{textAlign:'center', padding:30}}>
                <Button onClick={handleStartProject} variant="contained" style={{backgroundColor:'#d35396'}} >
                    Start A Project
                </Button>
            </Container>
            <PublicFooter />
        </>
    )
}