import { Favorite } from "@mui/icons-material";
import { Grid, Button, Stack, Card, CardMedia, CardContent, Typography, Container } from "@mui/material";
import { PublicFooter } from "components/PublicFooter";
import { PublicMenu } from "components/PublicMenu";

export default function HowWeDoIt(){

    const handleStartProject = () => {
        window.location.replace('/start/project')
    }

    let data = [
        {image:"/images/we-do-medical-equipment.jpg", title:"Medical Equipment:", body:"When it comes to medical equipment, Insurance or Medicare may not cover all of your loved one’s needs. HelpGrandma can guide you through raising theadditional funds you need for the quality equipment you require, or insurance won’t cover. Don’t compromise. Let us help. Simply shop for the wheelchair or equipment you need, then use HelpGrandma to collaborate, fund and go!"},
        {image:"/images/we-do-medical-services.jpg", title:"Medical Services:", body:"Medical Services and assistance are vital for those who need it. Whether your need is home health nursing, a home caregiver, transportation, meals or more extensive medical care, use HelpGrandma. Collaborate with your CARE TEAM of family or friends tocoordinate services and help solve funding needs for your loved one."},
        {image:"/images/we-do-transitions.jpg", title:"Transitions:", body:"When your loved one faces a change in residence, financial challenges or retrofitting their home, you need a transition plan. Resources available through HelpGrandma can help you build your transition team. Monthly Payments, or Medical Bills? Help finding or affording an Assisting Living or Nursing Home option? Find a Real Estate professional, or Financial Planning expert? Get professionals on your team with HelpGrandma."},
        {image:"/images/other_needs.jpg", title:"Other Needs:", body:"Wherever your heart leads you to help with the needs of others, whether it is an individual, group or organization. Start a Project, build your team, and get going!"},
 ]

    return (
        <>
            <Grid container style={{backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.20), rgba(1, 1, 1, 0.20)),url("/images/public_banner.jpg")', backgroundPosition:'center', backgroundSize:'cover', padding:20, paddingBottom:100}}>
                <PublicMenu />
                <Grid item xs={12} style={{textAlign:'center'}}>
                    <p style={{color:'white', fontFamily:'Satisfy, cursive', fontSize:'2em', marginBottom:0}}>
                        <Favorite fontSize="4em" />
                        <br />
                        How We Do It
                    </p>
                </Grid>
            </Grid>
            <Container style={{padding:50}}>
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 1, sm: 2, md: 4 }}
                >
                    {data.map((item, index) =>
                    <Card sx={{ maxWidth: 345, minWidth: 250 }}>
                        <CardMedia
                            sx={{ height: 200 }}
                            image={item.image}
                            title={item.title}
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                            {item.title}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                            {item.body}
                            </Typography>
                        </CardContent>
                    </Card>
                    )}
                </Stack>
            </Container>
            <Container style={{textAlign:'center', padding:30}}>
                <Button onClick={handleStartProject} variant="contained" style={{backgroundColor:'#d35396'}} >
                    Start A Project
                </Button>
            </Container>
            <PublicFooter />
        </>
    )
}