import { Avatar, Button, Card, CardContent, FormControl, Stack, TextField } from '@mui/material';
import { 
  useCreate,
  useList,
  HttpError,
  useMany,
 } from '@pankod/refine-core';
import * as React from 'react';
import { LoggedInUser } from 'utils/auth.management';

interface IComment {
  id: number;
  resource_id:String,
  comment: string;
  account: string;
  firstName: string;
  lastName: string;
  avatar: string;
}

export const CommentBox = (details:any) => {
  const { mutate } = useCreate();
  const [comment, setComment] = React.useState<any>()
  let account = LoggedInUser()?.id

  const { data, isLoading, isError } = useList<IComment, HttpError>({
    resource: "comments",
  });

  const { data: allAccounts, isLoading:loadingAccounts, isError:accountError } = useMany<IComment, HttpError>({
      resource: "accounts",
      ids: [],
  });

  const comments = data?.data ?? [];

  const {
    resource,
    resource_id,
    record,
  } = details.details

  const onSubmit = () => {
    mutate({
      resource: "comments",
      values: {
          comment, 
          resource,
          resource_id,
          account,
      },
    });
    setComment('')
  }

  if (isLoading) {
      return <div>Loading...</div>;
  }

  if (isError) {
      return <div>Something went wrong!</div>;
  }

  return (
    <>
      <Card style={{height:'fit-content'}}>
        <CardContent style={{height:'55vh', minWidth:'50vw', backgroundColor:'aliceblue'}}>
            {comments.map((com) => (
              com.resource_id === resource_id  ?
              <>
                    <Stack key={com.id} style={{display:'flex', flexDirection:'row', alignItems:'center', margin:20}}>
                        <div style={{marginRight:5}}>
                          {allAccounts?.data?.filter((item:any) => item.id === com.account)[0]?.avatar ?
                            <Avatar alt="Remy Sharp" src={allAccounts?.data?.filter((item:any) => item.id === com.account)[0].avatar} />
                            :
                            <Avatar sx={{ bgcolor: 'purple' }}>{allAccounts?.data?.filter((item:any) => item.id === com.account)[0]?.firstName.slice(0,1)+""+allAccounts?.data?.filter((item:any) => item.id === com.account)[0]?.lastName.slice(0,1)}</Avatar>
                          }
                        </div>
                        {com.comment}
                    </Stack>
              </>
              :
              record?.projects.includes(com.resource_id) ?
              <>
                    <Stack key={com.id} style={{display:'flex', flexDirection:'row', alignItems:'center', margin:20}}>
                        <div style={{marginRight:5}}>
                          {allAccounts?.data?.filter((item:any) => item.id === com.account)[0]?.avatar ?
                            <Avatar alt="Remy Sharp" src={allAccounts?.data?.filter((item:any) => item.id === com.account)[0].avatar} />
                            :
                            <Avatar sx={{ bgcolor: 'purple' }}>{allAccounts?.data?.filter((item:any) => item.id === com.account)[0]?.firstName.slice(0,1)+""+allAccounts?.data?.filter((item:any) => item.id === com.account)[0]?.lastName.slice(0,1)}</Avatar>
                          }
                        </div>
                        {com.comment}
                    </Stack>
              </>
              :
              ""
            ))}
        </CardContent>
        <FormControl style={{padding:10, justifyContent:'center', display:'flex', marginBottom:10, width:'100%'}}>
          <TextField 
            id="standard-basic" 
            label="Your comment" 
            variant="standard" 
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
          <hr />
          <Button 
            onClick={onSubmit}
            variant="contained"
            disabled={LoggedInUser() ? false : true}
          >{LoggedInUser() ? 'Save' : 'Please log in'}</Button>
        </FormControl>
      </Card>
      </>
  );
}