import React from "react";
import { LayoutProps } from "@pankod/refine-core";
import { Header as DefaultHeader, Box, Tabs, Tab } from "@pankod/refine-mui";
import {
    useLogout,
} from "@pankod/refine-core";

import { CustomSider } from "./CustomSider";
import { useNavigate } from "@pankod/refine-react-router-v6";
export const CustomLayout: React.FC<LayoutProps> = ({
    Header,
    Footer,
    OffLayoutArea,
    children,
}) => {
    const HeaderToRender = Header ?? DefaultHeader;
    const navigate = useNavigate();
    const { mutate: mutateLogout } = useLogout();

    return (
        <>
        <div style={{ display:'flex', justifyContent:'end', textAlign: 'center', backgroundColor:'#f4a9d0', color:'white' }}>
        <Tabs
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
            value={0}
        >
            <Tab style={{color:"white"}} label="Home" onClick={() =>  navigate("/home")} />
            <Tab style={{color:"white"}} label="What We Do" onClick={() =>  navigate("/what-we-do")} />
            <Tab style={{color:"white"}} label="How We Do It" onClick={() =>  navigate("/how-we-do-it")} />
            <Tab style={{color:"white"}} label="Contact" onClick={() =>  navigate("/contact-us")} />
            <Tab style={{color:"white"}} label="Dashboard" onClick={() =>  navigate("/")} />
            <Tab style={{color:"white"}} label="Logout" onClick={() => mutateLogout()} />
        </Tabs>
        </div>
        <div style={{backgroundSize:'cover', backgroundPosition:'right', backgroundImage:'linear-gradient(to bottom, rgba(0, 0, 0, 0.20), rgba(1, 1, 1, 0.20)),url("https://helpgrandma.com/images/public_banner.jpg")', height:200}}></div>

        <Box display="flex" flexDirection="row">
            <CustomSider />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    minHeight: "100vh",
                }}
            >
                <HeaderToRender />
                <Box
                    component="main"
                    sx={{
                        p: { xs: 1, md: 2, lg: 3 },
                        flexGrow: 1,
                        bgcolor: "background.default",
                    }}
                >
                    {children}
                </Box>
                {Footer && <Footer />}
            </Box>
            {OffLayoutArea && <OffLayoutArea />}
        </Box>
        </>
    );
};