import React, { useState } from "react";
import { Box, Button, Container, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import axios from "axios";
import { useModal } from "@pankod/refine-core";
import { LoggedInUser } from "utils/auth.management";


export const CreateVendor = (details:any) => {
    const [accountName, setAccountName] = useState('')
    const [accountNumber, setAccountNumber] = useState('')
    const [routingNumber, setRountingNumber] = useState('')
    const [address1, setaddress1] = useState('')
    const [city, setcity] = useState('')
    const [region, setregion] = useState('')
    const [postalCode, setpostalCode] = useState('')
    const [electronicAccountType, setElectronicAccountType] = useState('')
    const { id, resource } = details
    let vendor_id = Math.floor(Math.random() * 10000)+id
    const { visible, show, close } = useModal();

    const address = {
        address1,
        city,
        region,
        postalCode,
        country:"USA"
    }

    const createVendor = async() => {
        try {
            const res = await axios.post(process.env.REACT_APP_API+'/mercury/recipient', { id, name:accountName, emails:[LoggedInUser()?.email], accountNumber, routingNumber, electronicAccountType, address })
            alert('Your ACH has been created')

            close()
        } catch (error:any) {
            console.log(error.response.data.errors)
            alert(JSON.stringify(error.response.data.errors))
        }
    }

    return (
        <>
        <div style={{marginBottom:20}}>
            {!visible && <Button onClick={show} variant="contained">Setup Payout</Button>}
        </div>
        <div>
            {visible && (
                <>
                <InputLabel>Account Type</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name='electronicAccountType'
                    value={electronicAccountType}
                    label="Account Type"
                    style={{margin:5, minWidth:100}}
                    onChange={(e) => setElectronicAccountType(e.target.value)}
                    required
                >
                    <MenuItem value={'businessChecking'}>Business Checking</MenuItem>
                    <MenuItem value={'businessSavings'}>Business Savings</MenuItem>
                    <MenuItem value={'personalChecking'}>Personal Checking</MenuItem>
                    <MenuItem value={'personalSavings'}>Personal Savings</MenuItem>
                </Select>
                <InputLabel>Account Details</InputLabel>
                <Box>
                <TextField
                    label='Account Name'
                    margin="normal"
                    variant="outlined"
                    onChange={(e) => setAccountName(e.target.value)}
                    name='account_name'
                    style={{margin:5}}
                />
                <TextField
                    label='Account Number'
                    margin="normal"
                    variant="outlined"
                    onChange={(e) => setAccountNumber(e.target.value)}
                    name='account_number'
                    style={{margin:5}}
                />
                <TextField
                    label='Routing Number'
                    margin="normal"
                    variant="outlined"
                    onChange={(e) => setRountingNumber(e.target.value)}
                    name='routing_number'
                    style={{margin:5}}
                />

                </Box>
                <InputLabel>Address</InputLabel>
                {[
                    {name:'address1', label:'Address', value:address1, setValue:setaddress1},
                    {name:'city', label:'City', value:city, setValue:setcity},
                    {name:'region', label:'State', value:region, setValue:setregion},
                    {name:'postalCode', label:'Zip Code', value:postalCode, setValue:setpostalCode},
                ].map((item, index) =>
                    <TextField
                        key={index}
                        label={item.label}
                        margin="normal"
                        variant="outlined"
                        onChange={(e) => item.setValue(e.target.value)}
                        name={item.name}
                        style={{margin:5}}
                    />
                )}
                <div style={{marginBottom:20}}>
                    <Button 
                    variant="contained"
                    onClick={createVendor}>Submit Payout</Button>
                </div>
                </>
            )}
        </div>
        </>
    )
}