import { ContactMail } from "@mui/icons-material"
import { Container, Grid } from "@mui/material"
import { PublicFooter } from "components/PublicFooter"
import { PublicMenu } from "components/PublicMenu"

export const TermsOfService = () => {

    return (
        <>
        <Grid container style={{backgroundColor:'gray', backgroundPosition:'center', backgroundSize:'cover', padding:20, paddingBottom:100}}>
            <PublicMenu />
            <Grid item xs={12} style={{textAlign:'center'}}>
                <p style={{color:'white', fontFamily:'Satisfy, cursive', fontSize:'2em', marginBottom:0}}>
                     Terms and Conditions Agreement
                </p>
            </Grid>
        </Grid>
        <Container style={{textAlign:'center', width:'auto'}}>
            <iframe 
                style={{margin:'auto', width:'70%'}}
                height="700px"
                src="https://docs.google.com/document/d/e/2PACX-1vRH_Fxw6H_J4pPFyQMdDD9jIH517U4tkWFnIYdd9TjE-iSE2lg0kqSoB-6jH7ZHcSRdbHzvM6siOK-T/pub?embedded=true"></iframe>
        </Container>
        <PublicFooter />
        </>
    )
}