import {
    TextField,
    Autocomplete,
    Button,
} from "@pankod/refine-mui";
import { useState, useEffect } from "react";
import { useCustomMutation, useMany } from "@pankod/refine-core";
import { Delete } from "@mui/icons-material";
import { Box, Card, CardContent, Container, Grid } from "@mui/material";
import axios from 'axios'
import { MembersList } from "./MemberList";

export const TeamAddMember = (props:any) => {

    const { team_id, members, founder_name, team_name } = props

    let manageMembersButton = [
        {label:'Add a registered member to this Care Team', addMemberShow:true, inviteShow:false},
        {label:'Invite a NEW MEMBER (not registered) to join your Care Team', addMemberShow:false, inviteShow:true},
    ]

    const { data: accounts } = useMany({
        resource: "accounts",
        ids: [],
    });
    
    const { mutate } = useCustomMutation();

    const [projectsField, setProjectsField] = useState<any>([])
    const [allRecords, setAllRecords] = useState<any>([])
    const [addMemberShow, setAddMemberShow]  = useState<any>(false)
    const [inviteShow, setInviteShow] = useState<any>(false)
    const [emailToInvite, setEmailInvite] = useState('')

    let apiUrl = process.env.REACT_APP_API

    const handleMember = (x:any) => {
        setProjectsField([...projectsField, ...members, x.id])
    }

    const handleSubmit = () => {

        mutate({
            url: `${apiUrl}/teams/${team_id}`,
            method: "patch",
            values: {
                members: projectsField,
            },
        });

        setTimeout(() => { 
            window.location.reload()
        }, 2000);
    };

    const handleDelete = (id:any) => {
        let array = members
        const index = array.indexOf(id);
        if (index > -1) { // only splice array when item is found
            let deletedItem = array.splice(index, 1); // 2nd parameter means remove one item only
            let newAr = members.filter((item:any) => item !== deletedItem)
            setProjectsField(newAr)
        }
        
        var answer = window.confirm('Do you want to remove this member from the team?');
        if (answer) {
            mutate({
                url: `${apiUrl}/teams/${team_id}`,
                method: "patch",
                values: {
                    members,
                },
            });
        }
    }

    useEffect(() => {
      let localAllRecords:any[] = []
      if(members.length > 0){
        accounts?.data.filter((item:any) => !members?.includes(item.id) && localAllRecords.push({...item, ...{label:item.firstName}}))
      } else {
        accounts?.data.filter((item:any) => localAllRecords.push({...item, ...{label:item.firstName}}))
      }
      setAllRecords(localAllRecords)
    }, [accounts, members])

    const sendEmailInvitation = () => {
        axios.post(`${apiUrl}/teams/invite/${team_id}`, {to:emailToInvite, founder:founder_name, team_name, team_id:team_id})
        setEmailInvite('')
        alert('Invitation has been sent')
    }

    return (
        <Container>
            <Grid container spacing={2} style={{marginTop:5}}>
            {manageMembersButton.map((item, index) =>
                <Grid key={index} style={{margin:5}}>
                    <Card 
                        onClick={() => {
                            setAddMemberShow(item.addMemberShow)
                            setInviteShow(item.inviteShow)
                        }} 
                        key={index} 
                        sx={{ cursor:'pointer', maxWidth:200 }}
                    >
                        <CardContent>{item.label}</CardContent>
                    </Card>
                </Grid>
            )}
            </Grid>

            {addMemberShow &&
            <Box
                component="form"
                sx={{
                '& > :not(style)': { m: 1, width: '45ch' },
                }}
                noValidate
                autoComplete="off"
            >
                <Autocomplete
                    disablePortal
                    
                    onChange={(_, value) => {
                        handleMember(value)
                    }}
                    id="combo-box-demo"
                    options={allRecords}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField  {...params} label="Members" />}
                    fullWidth
                />
                <Button variant="contained" onClick={handleSubmit}>Add Member</Button>
            </Box>
            }

            {inviteShow &&
            <Box
                component="form"
                sx={{
                '& > :not(style)': { m: 1, width: '45ch' },
                }}
                noValidate
                autoComplete="off"
            >
                <TextField 
                    id="outlined-basic" 
                    label="Email Invitation" 
                    onChange={(e) => setEmailInvite(e.target.value)}
                    value={emailToInvite} 
                    variant="outlined" 
                    fullWidth
                />
                    <br />
                <Button variant="contained" onClick={sendEmailInvitation}>Send Invitation</Button>
            </Box>
            }

            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>                
                {

                    members?.map((item:any, index:number) =>
                    <Grid item xs={2} sm={4} md={4} key={index}>
                        <Delete onClick={() => handleDelete(item)} style={{color:'red', marginBottom:-20, zIndex:100, cursor:'pointer', position:'relative'}} />
                        <MembersList id={item} edit_status={true} />
                    </Grid>
                    )
                
                }
            </Grid>
        </Container>
    );
};