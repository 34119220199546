import { Refine } from "@pankod/refine-core";
import { AuthPage } from "components/pages/auth";
import {
  notificationProvider,
  RefineSnackbarProvider,
  CssBaseline,
  GlobalStyles,
  ThemeProvider,
  ErrorComponent,
  LightTheme,
} from "@pankod/refine-mui";

import { dataProvider } from "./rest-data-provider";
import routerProvider from "@pankod/refine-react-router-v6";
import { ColorModeContextProvider } from "contexts";
import { authProvider } from "./authProvider";
import { ProjectList } from "components/pages/projects/List";
import { ProjectCreate } from "components/pages/projects/Create";
import { ProjectShow  } from "components/pages/projects/Show";
import { ProjectEdit } from "components/pages/projects/Edit";
import { CustomLayout } from "components/layout";
import { TeamList } from "components/pages/teams/List";
import { TeamCreate } from "components/pages/teams/Create";
import { TeamShow  } from "components/pages/teams/Show";
import { TeamEdit } from "components/pages/teams/Edit";
import { MuiInferencer } from "@pankod/refine-inferencer/mui";
import LandingPage from "components/pages/extra_pages/landing.page";
import { UserAccountPage } from "components/pages/extra_pages/account.page";
import { UserNotificationPage } from "components/pages/extra_pages/notifications.page";
import { DesignServices, Diversity1 } from "@mui/icons-material";
import WhatWeDo from "components/pages/extra_pages/what-we-do.page";
import HowWeDoIt from "components/pages/extra_pages/how-we-do-it.page";
import { PublicProjectView } from "components/publicProjectView";
import { ContactUsPage } from "components/pages/extra_pages/contact_us.page";
import { TermsOfService } from "components/pages/extra_pages/terms_of_service.page";
import { VerifyUser } from "components/pages/extra_pages/verifyUser";

const overridedLightTheme = {
  ...LightTheme,
  palette: {
      ...LightTheme.palette,
      primary: {
          main: "#f4a9d0",
      },
      secondary: {
          main: "#d35396",
      },
  },
};

function App() {
  return (
    <ColorModeContextProvider>
    <ThemeProvider theme={overridedLightTheme}>
      <CssBaseline />
      <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
      <RefineSnackbarProvider>
        <Refine
          dataProvider={dataProvider(process.env.REACT_APP_API || "")}
          notificationProvider={notificationProvider}
          Layout={CustomLayout}
          ReadyPage={LandingPage}
          catchAll={<ErrorComponent />}
          routerProvider={{
              ...routerProvider,
              routes: [
                  { path: "/", element: <LandingPage /> },
                  { path: "/home", element: <LandingPage /> },
                  { path: "/what-we-do", element: <WhatWeDo /> },
                  { path: "/how-we-do-it", element: <HowWeDoIt /> },
                  { path: "/contact-us", element: <ContactUsPage /> },
                  { path: "/login", element: <AuthPage /> },
                  { path: "/verify/:token", element: <VerifyUser /> },
                  { path: "/register", element: <AuthPage type="register" /> },
                  { path: "/register/:team_id", element: <AuthPage type="register" /> },
                  { path: "/terms-conditions", element: <TermsOfService /> },
                  {
                    path: "/forgot-password",
                    element: <AuthPage type="forgotPassword" />,
                  },
                  {
                    path: "/update-password/:token",
                    element: <AuthPage type="updatePassword" />,
                  },
                  {
                    path: "/user/account",
                    element: <UserAccountPage />,
                  },
                  {
                    path: "/user/notifications",
                    element: <UserNotificationPage />,
                  },
                  {
                    path: "/start/project",
                    element: <ProjectCreate />,
                  },
                  {
                    path: "/project/:project_id/",
                    element: <PublicProjectView />,
                  },
              ],
          }}
          resources = {[
            {
              name: 'projects',
              options: { label: "My Projects" },
              list: ProjectList,
              show: ProjectShow,
              create: ProjectCreate,
              edit: ProjectEdit,
              icon: <DesignServices />,
              canDelete: true,
            },
            {
              name: 'teams',
              options: { label: "Care Teams" },
              list: TeamList,
              show: TeamShow,
              create: TeamCreate,
              edit: TeamEdit,
              icon: <Diversity1 />,
              canDelete: true,
            },
          ]}
          authProvider={authProvider}
          LoginPage={AuthPage}
        />
      </RefineSnackbarProvider>
    </ThemeProvider>
    </ColorModeContextProvider>
  );
}

export default App;
