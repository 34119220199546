import React, { useState } from "react";
import {
    useDataGrid,
    DataGrid,
    GridColumns,
    EditButton,
    ShowButton,
    List,
    TagField,
    DateField,
    Button,
    Grid,
    CardMedia,
    Card,
    CardContent,
    Typography,
    CardActions,
    ButtonGroup,
    DeleteButton,
    Container,
    Modal,
    Box,
} from "@pankod/refine-mui";
import { useMany, HttpError } from "@pankod/refine-core";
import { GridView, ListAlt, Person } from "@mui/icons-material";
import { Link } from "@pankod/refine-react-router-v6";
import { LoggedInUser } from "utils/auth.management";
import { style } from "utils/styles.custom";

export const TeamList = () => {
    const { dataGridProps } = useDataGrid();
    const [viewTableLayout, setViewTableLayout] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)

    const { data: projectData, isLoading: projectIsLoading } = useMany({
        resource: "projects",
        ids: dataGridProps?.rows?.map((item: any) => item?.project) ?? [],
        queryOptions: {
            enabled: !!dataGridProps?.rows,
        },
    });

    const { data: teams_data, isLoading: teams_isLoading } = useMany({
        resource: "teams",
        ids: [],
    });

    const { data: allAccounts, isLoading:loadingAccounts, isError } = useMany<HttpError>({
        resource: "accounts",
        ids: [],
    });

    const columns = React.useMemo<GridColumns<any>>(
        () => [
            {
                field: "members",
                headerName: "Members",
                minWidth: 200,
                renderCell: function render({ row }) {
                    return (
                        <>
                            {row?.members?.map((item: any) => (
                                <TagField value={item} key={item} />
                            ))}
                        </>
                    );
                },
            },
            {
                field: "name",
                headerName: "Name",
                minWidth: 200,
            },
            {
                field: "image",
                headerName: "Image",
                minWidth: 100,
                renderCell: function render({ value }) {
                    return (
                        <img
                            src={value}
                            style={{ height: "50px", maxWidth: "100px" }}
                        />
                    );
                },
            },
            {
                field: "project",
                headerName: "Project",
                minWidth: 300,
            },
            {
                field: "created",
                headerName: "Created",
                minWidth: 250,
                renderCell: function render({ value }) {
                    return <DateField value={value} />;
                },
            },
            {
                field: "actions",
                headerName: "Actions",
                renderCell: function render({ row }) {
                    return (
                        <>
                            {LoggedInUser().id === row.founder && <EditButton hideText recordItemId={row.id} />}
                            <ShowButton hideText recordItemId={row.id} />
                        </>
                    );
                },
                align: "center",
                headerAlign: "center",
                minWidth: 80,
            },
        ],
        [projectData?.data],
    );

    const founder_info = (record:any) => {
        let local_founder = allAccounts?.data.filter(item => item. id === record?.founder)[0]
        return local_founder?.firstName+" "+local_founder?.lastName
    }

    return (
        <List
            title="My Care Teams"
            headerButtons={({ defaultButtons }) => (
                <>
                    {defaultButtons}
                    <Button onClick={() => {
                        if(viewTableLayout){
                            setViewTableLayout(false)
                        } else {
                            setViewTableLayout(true)
                        }
                    }}>
                        {
                            viewTableLayout ?
                            <GridView />
                            :
                            <ListAlt />

                        }
                    </Button>
                </>
            )}
        >
            {
                viewTableLayout ?
                <DataGrid {...dataGridProps} columns={columns} autoHeight />
                :
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} style={{maxWidth:700, margin:'auto'}}>
                    {teams_data?.data.map((_, index) => (
                        <Grid item xs={2} sm={4} md={4} key={index}>
                            <Card style={{height:'85%', marginBottom:10}}>
                                <Typography gutterBottom variant="h5" component="div" style={{textAlign:'center'}}>
                                {_.name}
                                </Typography>
                                <img src={_.image} style={{width:'100%', maxHeight:200}} />
                                <CardContent>
                                    <Typography style={{display:'flex', alignItems:'center'}}>
                                        <Person /> {founder_info(_)+", Founder"}
                                    </Typography>
                                </CardContent>
                            </Card>
                            <Container style={{display:'flex', justifyContent:'center', padding:0}}>
                                
                                    <ButtonGroup>
                                        <Button size="small" variant="contained">
                                            <Link to={'show/'+_.id} style={{textDecoration:'none', color:'white'}}>
                                                View
                                            </Link>
                                        </Button>
                                        {LoggedInUser().id === _?.founder &&
                                        <>
                                            <Button size="small" variant="contained">
                                                <Modal
                                                    open={deleteModal}
                                                    onClose={() => setDeleteModal(false)}
                                                    aria-labelledby="modal-modal-title"
                                                    aria-describedby="modal-modal-description"
                                                >
                                                    <Box sx={style}>
                                                        <Typography id="modal-modal-title" variant="h6" component="h2">
                                                        Are you sure you want to delete your team?
                                                        </Typography>
                                                        <Button
                                                            onClick={() => setDeleteModal(false)}
                                                            style={{marginBottom:10}}
                                                        >
                                                            No, keep my team
                                                        </Button>
                                                        <DeleteButton variant="outlined" startIcon={<></>} recordItemId={_.id}>
                                                            Delete my team
                                                        </DeleteButton>
                                                    </Box>
                                                </Modal>
                                                <span 
                                                    onClick={() => {
                                                        setDeleteModal(true)
                                                    }}
                                                    style={{color:'white'}}
                                                >Delete
                                                </span>
                                            </Button>
                                            <Button size="small" variant="contained">
                                                <Link to={'edit/'+_.id} style={{textDecoration:'none', color:'white'}}>
                                                    Edit
                                                </Link>
                                            </Button>
                                        </>
                                        }
                                    </ButtonGroup>
                            </Container>
                        </Grid>
                    ))}
                </Grid>
            }

            {teams_data && teams_data?.data.length < 1 && <p style={{textAlign:'center'}}>To proceed it is best to FIRST <Link to="/projects/create">Start a Project</Link>, then create your CARE TEAM, and add Projects to your CARE TEAM.</p>}
        </List>
    );
};
