import {
    Edit,
    Box,
    useAutocomplete,
    TextField,
    Autocomplete,
    Button,
} from "@pankod/refine-mui";
import { useForm as useForm2, Controller } from "@pankod/refine-react-hook-form";
import ImageUploader from "utils/image.uploader";
import React, { useState, useEffect } from "react";
import { useForm } from "@pankod/refine-core";
import { SaveAsOutlined } from "@mui/icons-material";
import { CreateVendor } from "components/createVendor";

interface FormValues {
    _id: any;
    members: any;
    image: string;
    name: string;
    project: string;
    created: string;
    vendor_id: string;
}

export const TeamEdit = () => {
    const {
        saveButtonProps,
        //refineCore: { queryResult },
        register,
        control,
        formState: { errors },
    } = useForm2();
    
    const [mainProject, setMainProject] = useState('')
    const { formLoading, onFinish, queryResult } = useForm<FormValues>();
    const defaultValues = queryResult?.data?.data;
    const [ url, setUrl ] = useState(defaultValues?.image);
    let image = url

    //const teamsData = queryResult?.data?.data;
    const [formValues, seFormValues] = useState<FormValues>({
        _id: defaultValues?._id || "",
        members: defaultValues?.members || "",
        image: defaultValues?.image || "",
        name: defaultValues?.name || "",
        project: defaultValues?.project || "",
        created: defaultValues?.created || "",
        vendor_id: defaultValues?.vendor_id || "",
    });

    const handleOnChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        seFormValues({
            ...formValues,
            [e.target.name]: e.target.value
        });
        console.log(formValues)
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let id = formValues._id
        let allFormValues = {...formValues, image, id, project:mainProject}
        function clean(obj:any) {
            for (var propName in obj) {
              if (obj[propName] === null || obj[propName] === undefined) {
                delete obj[propName];
              }
            }
            return obj
          }
        onFinish(clean(allFormValues));
    };

    useEffect(() => {
        seFormValues({
            _id: defaultValues?._id || "",
            members: defaultValues?.members || "",
            image: defaultValues?.image || "",
            name: defaultValues?.name || "",
            project: defaultValues?.project || "",
            created: defaultValues?.created || "",
            vendor_id: defaultValues?.vendor_id || "",

        });
    }, [defaultValues]);

    const { autocompleteProps: projectAutocompleteProps } = useAutocomplete({
        resource: "projects",
        defaultValue: defaultValues?.project,
    });

    return (
        <Edit 
            footerButtons={({ defaultButtons }) => (
                <></>
            )}
        >

            <ImageUploader url={url} setUrl={setUrl} label={'Image'} name={'image'} />
            
            <form onSubmit={handleSubmit}>
                {/*<div>
                    {defaultValues?.members?.map((item: any, index: number) => (
                        <TextField
                            key={index}
                            {...register(`members.${index}`, {
                                required: "This field is required",
                            })}
                            error={!!(errors as any)?.members?.[index]}
                            helperText={
                                (errors as any)?.members?.[index]
                                    ?.message as string
                            }
                            margin="normal"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            type="text"
                            label={`Members ${index + 1}`}
                            name={`members.${index}`}
                        />
                    ))}
                </div>*/}
                <TextField
                    {...register("name", {
                        required: "This field is required",
                    })}
                    error={!!(errors as any)?.name}
                    helperText={(errors as any)?.name?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label="Name"
                    name="name"
                    onChange={handleOnChange}
                />
                {/* 
                    DatePicker component is not included in "@pankod/refine-mui" package.
                    To use a <DatePicker> component, you can follow the official documentation for Material UI.
                    
                    Docs: https://mui.com/x/react-date-pickers/date-picker/#basic-usage
                */}
                <TextField
                    {...register("created", {
                        required: "This field is required",
                    })}
                    error={!!(errors as any)?.created}
                    helperText={(errors as any)?.created?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label="Created"
                    name="created"
                    disabled
                />
                <TextField
                    {...register("id", {
                        required: "This field is required",
                    })}
                    error={!!(errors as any)?._id}
                    helperText={(errors as any)?._id?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label="ID"
                    name="id"
                    disabled
                    value={formValues._id}
                />

                {formValues.vendor_id ?
                    
                    <TextField
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="text"
                        label="Vendor ID"
                        name="vendor_id"
                        disabled
                        value={formValues.vendor_id}
                    />

                    :
                    
                    <CreateVendor id={formValues._id} resource={'teams'} />

                }

                <Button type="submit" disabled={formLoading} variant="contained" startIcon={<SaveAsOutlined />}>
                    {formLoading && <div>Loading...</div>}
                    <span>Save</span>
                </Button>
            </form>
        </Edit>
    );
};
