import React, { useEffect, useState } from 'react'
import { Box, Button, Card, CardContent, CardMedia, Container, Fab, Grid, LinearProgress, Typography } from '@mui/material'
import { Stack } from '@mui/system';
import { Link } from '@pankod/refine-react-router-v6';
import { CurrencyExchange, Groups3, Accessible, LocalPharmacy, OnlinePrediction, TransferWithinAStation, HandshakeRounded } from '@mui/icons-material';
import { PublicMenu } from 'components/PublicMenu';
import { PublicFooter } from 'components/PublicFooter';
import { useNavigate } from "@pankod/refine-react-router-v6";

export default function LandingPage() {

    const navigate = useNavigate();

    const handleStartProject = () => {
        window.location.replace('/start/project')
    }
    
    return(
        <>
        <Grid container style={{backgroundImage: 'url("/images/home_banner.jpg")', backgroundPosition:'center', backgroundSize:'cover', padding:20, paddingBottom:100}}>
            <PublicMenu />
            <Grid item xs={12} style={{textAlign:'center'}}>
                <p style={{color:'white', fontFamily:'Satisfy, cursive', fontSize:'3em', marginBottom:0}}>When they need you the most...</p>
                <p style={{color:'white', fontFamily:'Montserrat, sans-serif', fontSize:'3em', margin:0, fontWeight:900}}>Helping you help</p>
                <p style={{color:'#f4a9d0', fontFamily:'Montserrat, sans-serif', fontSize:'3em', margin:0, fontWeight:400}}>the one you love</p>
                <p style={{color:'white', fontFamily:'Satisfy, cursive', fontSize:'3em', marginTop:0}}>Don't let them down</p>
                <Button onClick={handleStartProject} variant="contained" style={{backgroundColor:'#d35396'}} >
                    Start A Project
                </Button>
            </Grid>
        </Grid>
        <Grid container spacing={2} columns={{ xs: 4, md: 12 }} style={{top:-30, position:'relative', maxWidth:'100%', width:1100, margin:'auto'}}>
            <Grid item xs={4} style={{width: '100%', background: 'url("/images/old-parent.jpg") center bottom no-repeat', backgroundSize: 'cover', minHeight:312}}>
            </Grid>
            <Grid item xs={8} style={{background:' url("/images/grunge-steel-bg.jpg") center center no-repeat', backgroundSize: 'cover', width: '100%', padding: '4% 9%', color:'white'}}>
                <h1>HEALTHCARE SOLUTIONS</h1>
                <p>
                    Get Help providing the quality products and services they deserve.
                    Don’t face it alone. Coordinate funding & support with family or friends.
                    Use <span style={{color:"#f4a9d0"}}>helpgrandma</span> to increase your loved one’s spending power.
                </p>
                <Grid container>
                    <Grid item xs={6}>
                        <h5 style={{color: '#ffffff', fontFamily: 'Montserrat,  sans-serif', fontWeight: 600, textTransform: 'none', fontSize: '1.7em', lineHeight:0}}>Raised:</h5>
                        <h4 style={{ color: '#d35396', fontFamily: 'Montserrat, sans-serif', fontWeight: 600, fontSize: '2.85em', lineHeight:0}}>$2,400</h4>
                    </Grid>
                    <Grid item xs={6} style={{display:'flex', alignItems:'center'}}>
                        <Button variant="contained" style={{backgroundColor:'#d35396'}} onClick={handleStartProject}>
                            Start A Project
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <span style={{color:'#f4a9d0', fontSize:20}}>Need help with your next steps...?</span>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{marginBottom:20}}>
                <h1 style={{fontFamily:'Satisfy, cursive', textAlign: 'center', fontSize: '2.5em', color: '#d35396', fontFamily: 'Satisfy, cursive', textTransform: 'capitalize', margin: '0'}}>What We Do</h1>
            </Grid>
            <Container>
                <Grid container style={{textAlign:'center'}}>
                    <Grid item xs={4} style={{padding:10}}>
                        <Fab onClick={() =>  navigate("/what-we-do")}>
                            <OnlinePrediction sx={{ fontSize: 75, color: 'rgb(211, 83, 150)' }} />
                        </Fab>
                        <h3>Connect</h3>
                        <p>BUILD YOUR CARE TEAM. Don’t Let loved one’s face things alone. Let helpgrandma help you connect with friends or family to build your care team.</p>
                    </Grid>
                    <Grid item xs={4} style={{padding:10}}>
                        <Fab onClick={() =>  navigate("/what-we-do")}>
                            <Groups3 sx={{ fontSize: 75, color: 'rgb(211, 83, 150)' }} />
                        </Fab>
                        <h3>Collaborate</h3>
                        <p>Collaborate with your CARE TEAM of family or friends to help solve your loved one’s needs. </p>
                    </Grid>
                    <Grid item xs={4} style={{padding:10}}>
                        <Fab onClick={() =>  navigate("/what-we-do")}>
                            <CurrencyExchange sx={{ fontSize: 75, color: 'rgb(211, 83, 150)' }} />
                        </Fab>
                        <h3>Fund</h3>
                        <p>Once you’ve decided upon projects and funding goals, begin your funding campaign for medical services, nursing home care, or other projects for your loved one.</p>
                    </Grid>
                </Grid>
            </Container>
            <Grid item xs={12} style={{marginBottom:20}}>
                <h1 style={{fontFamily:'Satisfy, cursive', textAlign: 'center', fontSize: '2.5em', color: '#d35396', fontFamily: 'Satisfy, cursive', textTransform: 'capitalize', margin: '0'}}>How Do We Do It</h1>
            </Grid>
            <Container>
                <Grid container style={{textAlign:'center'}}>
                    <Grid item xs={3} style={{padding:10}}>
                        <Fab onClick={() =>  navigate("/how-we-do-it")}>
                            <Accessible sx={{ fontSize: 75, color: 'rgb(211, 83, 150)' }} />
                        </Fab>
                        <h3>Equipment</h3>
                        <p>Let us help! Simply shop for the wheelchair or equipment you need, then use helpgrandma to collaborate, fund and go.</p>
                    </Grid>
                    <Grid item xs={3} style={{padding:10}}>
                        <Fab onClick={() =>  navigate("/how-we-do-it")}>
                            <LocalPharmacy sx={{ fontSize: 75, color: 'rgb(211, 83, 150)' }} />
                        </Fab>
                        <h3>Medical</h3>
                        <p>Home health nursing, home assistance, or other medical services, get connected to the right company and use helpgrandma to make it happen.</p>
                    </Grid>
                    <Grid item xs={3} style={{padding:10}}>
                        <Fab onClick={() =>  navigate("/how-we-do-it")}>
                            <TransferWithinAStation sx={{ fontSize: 75, color: 'rgb(211, 83, 150)' }} />
                        </Fab>
                        <h3>Transitions</h3>
                        <p>Do you need help with Assisted Living, home safety and accessibility, or a change in residence?   Financial planning?  Monthly Payments?  Get professionals on your team to help with your loved one’s changing needs, or the transition to a new living situation. </p>
                    </Grid>
                    <Grid item xs={3} style={{padding:10}}>
                        <Fab onClick={() =>  navigate("/how-we-do-it")}>
                            <HandshakeRounded sx={{ fontSize: 75, color: 'rgb(211, 83, 150)' }} />
                        </Fab>
                        <h3>Other Needs</h3>
                        <p>Wherever your heart leads you to help with the needs of others, whether it is an individual, group or organization. Start a Project, build your team, and get going! </p>
                    </Grid>
                </Grid>
            </Container>
        </Grid>
        <Grid container>
            <Grid item xs={4} style={{width: '100%', background: 'url("/images/red-heart.jpg") center bottom no-repeat', backgroundSize: 'cover'}}>
            </Grid>
            <Grid item xs={8} style={{background:' url("/images/grunge-steel-bg.jpg") center center no-repeat', backgroundSize: 'cover', width: '100%', padding: '4% 9%', color:'white'}}>
                <h1 style={{fontFamily: 'Satisfy, cursive', lineHeight:1, fontSize: '3.5em', color: '#d35396', textTransform: 'none', margin: '0 0 0.4em 0'}}>Helping you help the one you love</h1>
                <h3 style={{margin:0, lineHeight:1, fontFamily:'Montserrat, sans-serif', fontSize:'2.5em', letterSpacing:2, fontWeight:800}}>Don't leave your loved one</h3>
                <p style={{margin:0, lineHeight:1.5, fontSize:'2.5em', fontFamily:'Montserrat, sans-serif', fontWeight:200}}>with unmet needs.</p>
                <p>
                    Let us help bring the right solutions to your loved one.
                    Build Your Care Team with <span style={{color:'#f4a9d0'}}>helpgrandma</span>, and Fund a Project for the quality they deserve. 
                </p>
                <Button variant="contained" style={{backgroundColor:'#d35396'}} onClick={handleStartProject}>
                    Start A Project
                </Button>
            </Grid>
        </Grid>
        <Grid container>
            <Grid item xs={12} style={{marginBottom:100}}>
                <div style={{textAlign:'center', }}>
                    <h1>Projects</h1>
                    <h4>Healthcare Solutions</h4>
                </div>
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 1, sm: 2, md: 4 }}
                    style={{display:'flex', alignItems:'center', justifyContent:'center'}}
                >
                    <Box>
                    <h3 style={{textAlign:'center'}}>Medical Equipment</h3>
                    <Card sx={{ maxWidth: 245, height:650 }}>
                        <img src="images/brad-wheelchair.jpg" style={{width:'100%'}} />
                        <LinearProgress variant="determinate" value={15} style={{marginTop:-10, height:20}} /> 
                        <span>15%</span>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                            Wheelchair for Brad
                            </Typography>
                            
                            <Typography variant="body2" color="text.secondary">
                            Brad needs a wheelchair so he can get back to cooking. Can you help Brad get his wheelchair? 
                            </Typography>

                            <h2>Target: <br /> $3,200</h2>
                            
                            <p>Thank you for your Kindness.</p>
                            
                            <p>Project End Date:<br /> July 31, 2023</p>
                        
                        </CardContent>
                    </Card>
                    </Box>
                    <Box>
                    <h3 style={{textAlign:'center'}}>Medical Services</h3>
                    <Card sx={{ maxWidth: 245, height:650 }}>
                        <img src="images/julies_surgery.jpg" style={{width:'100%'}} />

                        <LinearProgress variant="determinate" value={35} style={{marginTop:-10, height:20}} /> 
                        35%
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                            Judy's Surgery Needs
                            </Typography>
                            
                            <Typography variant="body2" color="text.secondary">
                            Plese help Judy with her much needed Surgery.                            
                            </Typography>

                            <h2>Target:<br /> $8,000</h2>
                            
                            <p>Thank you for your Kindness.</p>
                            
                            <p>Project End Date:<br /> May 31, 2023</p>
                        
                        </CardContent>
                    </Card>
                    </Box>
                    <Box>
                    <h3 style={{textAlign:'center'}}>Transitions</h3>
                    <Card sx={{ maxWidth: 245, height:650 }}>
                        <img src="images/johns_move.png" style={{width:'100%'}} />

                        <LinearProgress variant="determinate" value={80} style={{marginTop:-10, height:20}} /> 
                        80%
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                            John's Move to Assisted Living
                            </Typography>
                            
                            <Typography variant="body2" color="text.secondary">
                            Elsie's Assisted Living - first two (2) month's rent.                
                            </Typography>

                            <h2>Target:<br /> $14,000</h2>
                            
                            <p>Thank you for your Kindness.</p>
                            
                            <p>Project End Date:<br /> May 31, 2023</p>
                        
                        </CardContent>
                    </Card>
                    </Box>
                    <Box>
                    <h3 style={{textAlign:'center'}}>Other Needs</h3>
                    <Card sx={{ maxWidth: 245, height:650 }}>
                        <img src="images/other_needs.jpg" style={{width:'100%'}} />

                        <LinearProgress variant="determinate" value={80} style={{marginTop:-10, height:20}} /> 
                        10%
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                            Meal Program for Arthur
                            </Typography>
                            
                            <Typography variant="body2" color="text.secondary">
                            Feeding Arthur for hope.               
                            </Typography>

                            <h2>Target:<br /> $12,000</h2>
                            
                            <p>Thank you for your Kindness.</p>
                            
                            <p>Project End Date:<br /> July 31, 2023</p>
                        
                        </CardContent>
                    </Card>
                    </Box>
                </Stack>
            </Grid>
        </Grid>
        <PublicFooter />
        </>
    )
}