import { Container, LinearProgress } from "@mui/material";
import { useNavigate, useParams } from "@pankod/refine-react-router-v6";
import axios from "axios"
import { useEffect, useState } from "react"


export const VerifyUser = () => {
    const { token } = useParams()
    let navigate = useNavigate()
    const [counter, setCounter] = useState(0)
    const [act, setAct] = useState(false)
    const [actErr, setActErr] = useState(false)
        
    var seconds = 0;

    useEffect(() => {
        axios
        .put(process.env.REACT_APP_API+`/accounts/verify-user/${token}`)
        .then(res => {
            console.log(res.data.success)
            if(res.data.success === true){
                setAct(true)
                
                setTimeout(() => {
                    navigate('/login')
                }, 3000);
            }
        })
        .catch(err => console.log(err.message))
    }, [])
    
    useEffect(() => {
        const handleTimer = setInterval(function() {
            setCounter(seconds++);
        }, 1000);

        if(counter > 20){
            clearInterval(handleTimer)
            setActErr(true)
        }
    }, [counter])
    

    return (
        <Container style={{padding:100}}>
            <LinearProgress />
            <h3>{actErr && "There has been an error "}{act ? "Account Activated" : "Activating Account"}</h3>
        </Container>
    )
}