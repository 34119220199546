import React, { useState } from "react";
import {
  RegisterPageProps,
  useTranslate,
  useRouterContext,
  useRegister,
} from "@pankod/refine-core";
import { Alert, Button, Checkbox, FormControlLabel, IconButton, InputAdornment, Stack, TextField } from "@mui/material";
import { useParams } from "@pankod/refine-react-router-v6";
import { PasswordBlocker } from "components/passwordBlock";
import { Visibility, VisibilityOff } from "@mui/icons-material";


type DivPropsType = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;
type FormPropsType = React.DetailedHTMLProps<
  React.FormHTMLAttributes<HTMLFormElement>,
  HTMLFormElement
>;

type RegisterProps = RegisterPageProps<
  DivPropsType,
  DivPropsType,
  FormPropsType
>;

export const RegisterPage: React.FC<RegisterProps> = ({
  providers,
  loginLink,
  wrapperProps,
  contentProps,
  renderContent,
  formProps,
}) => {
  const { Link } = useRouterContext();

  const [email, setEmail] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [street, setStreet] = useState("");
  const [unit_apt, setApt] = useState(" ");
  const [city, setCity] = useState("");
  const [state, setCState] = useState("");
  const [zip_code, setZipCode] = useState("");
  const [cell_phone, setCellPhone] = useState("");
  const [acceptTerms, setAcceptTerms] = useState(false);
  const { team_id } = useParams()
  let startedProject = localStorage.getItem('project')

  let registration_fields = [
    {fieldValue:firstName, setField:setFirstName, type:"text", label:'First Name', name:'f_name', required:true},
    {fieldValue:lastName, setField:setLastName, type:"text", label:'Last Name', name:'l_name', required:true},
    {fieldValue:email, setField:setEmail, type:"email", label:'Email', name:'email', required:true},
    {fieldValue:street, setField:setStreet, type:"text", label:'Street', name:'street', required:true},
    {fieldValue:unit_apt, setField:setApt, type:"text", label:'APT', name:'apt', required:false},
    {fieldValue:city, setField:setCity, type:"text", label:'City', name:'city', required:true},
    {fieldValue:state, setField:setCState, type:"text", label:'State', name:'state', required:true},
    {fieldValue:zip_code, setField:setZipCode, type:"text", label:'Zip Code', name:'zip_code', required:true},
    {fieldValue:cell_phone, setField:setCellPhone, type:"text", label:'Cell Phone', name:'cell_phone', required:true},
    {fieldValue:password, setField:setPassword, type:"password", label:'Password', name:'password', required:true},
    {fieldValue:confirmPassword, setField:setConfirmPassword, type:"password", label:"Confirm Password", name:"confirm_password", required:true},
  ]

  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const translate = useTranslate();

  const { mutate: register, isLoading } = useRegister();

  const renderLink = (link: React.ReactNode, text?: string) => {
    if (link) {
      if (typeof link === "string") {
        return <Link to={link}>{text}</Link>;
      }
      return link;
    }
    return null;
  };

  const content = (
    <div className="auth-form" {...contentProps} style={{display:'flex', justifyContent:'center', boxShadow:'none'}}>
      <div /*className="outer-outer-form"*/ style={{maxWidth:850, margin:30}}>
      <div className="outer-auth-form">
        {startedProject &&
          <Alert severity="error">Please register — to start your project!</Alert>
        }
      <h1 className="auth-title">
        {translate("pages.register.title", "Sign up for your account")}
      </h1>
      {/*renderProviders()*/}
      <hr />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          register({ email, password, firstName, lastName, acceptTerms,  confirmPassword, street, unit_apt, city, state, zip_code, cell_phone, team_id},
            {
                onError: (data) => {
                  console.log(data)
                },
                onSuccess: (data) => {
                  alert('Please use the link sent to your email address to confirm your Registration.')
                },
            },)
        }}
        {...formProps}
      >
        <div style={{width:'100%'}}>
          <Stack spacing={{ xs: 1, sm: 2 }} direction="row" flexWrap="wrap">
            {registration_fields.map((item, index) => 
              <TextField 
                key={index}
                id="outlined-basic" 
                label={item.label}
                variant="outlined" 
                name={item.name}
                type={showPassword === true ? 'text' : item.type}
                autoCorrect="off"
                spellCheck={false}
                autoCapitalize="off"
                required={item.required}
                value={item.fieldValue}
                onChange={(e) => item['setField'](e.target.value)}
                sx={{ width: 1 }}
                style={{margin:'0px 5px 10px', maxWidth:250}}
                InputProps={{
                  endAdornment:(
                    item.type === 'password' &&
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
              }}
              />
            )}

          </Stack>
          
          {team_id &&
          <FormControlLabel 
            control={
              <Checkbox />
            } 
            label="I understand that I’ve been asked to join a fund raising effort, and that my contribution(s) will be sent to an account that (founding donor name) has designated." 
          />}

          <Link to="/terms-conditions" target="_blank">Terms and Conditions Agreement</Link>
          <br />
          <FormControlLabel 
            control={
              <Checkbox 
                checked={acceptTerms} 
                onChange={() => {
                  if(acceptTerms){
                    setAcceptTerms(false)
                  } else {
                    setAcceptTerms(true)
                  }
                }} 
              />
            } 
            label="I agree" 
          />
          {loginLink ?? (
            <>
            <div style={{textAlign:'right'}}>
              <span className="auth-links">
                {translate(
                  "pages.login.buttons.haveAccount",
                  "Have an account?"
                )}{" "}
                {renderLink(
                  "login",
                  translate("pages.login.signin", "Sign in")
                )}
              </span>
            </div>
            </>
          )}

          <div style={{margin:10}}>
            <Button sx={{ width: 1 }} type="submit" disabled={isLoading} variant="contained">{translate("pages.register.buttons.submit", "Sign up")}</Button>          
          </div>
         
        </div>
      </form>
      </div>
      </div>
    </div>
  );

  return (
    <>
    <PasswordBlocker />
    <div className="background-auth-page"  {...wrapperProps}>
      {renderContent ? renderContent(content) : content}
    </div>
    </>
  );
};
