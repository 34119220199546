import {
    Create,
    useAutocomplete,
    TextField,
    Autocomplete,
    Button,
} from "@pankod/refine-mui";
import { useForm as useForm2 } from "@pankod/refine-react-hook-form";
import ImageUploader from "utils/image.uploader";
import React, { useState } from "react";
import { useForm } from "@pankod/refine-core";
import { SaveAsOutlined } from "@mui/icons-material";
import { LoggedInUser } from "utils/auth.management";

interface FormValues { }

export const TeamCreate = () => {
    const {
        saveButtonProps,
        //refineCore: { formLoading },
        register,
        control,
        formState: { errors },
    } = useForm2();

    const { autocompleteProps: projectAutocompleteProps } = useAutocomplete({
        resource: "projects",
    });
    const { formLoading, onFinish, queryResult } = useForm<FormValues>();
    const defaultValues = queryResult?.data?.data;
    
    const [formValues, seFormValues] = useState<FormValues>({ });
    const [project, setProject] = useState('')
    
    const [ url, setUrl ] = useState("");
    let image = url

    const handleOnChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        seFormValues({
            ...formValues,
            [e.target.name]: e.target.value,
            founder: LoggedInUser().id
        });
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let allFormValues = {...formValues, image, project}
        function clean(obj:any) {
            for (var propName in obj) {
              if (obj[propName] === null || obj[propName] === undefined) {
                delete obj[propName];
              }
            }
            return obj
          }
          console.log(clean(allFormValues))
        onFinish(clean(allFormValues));
    };

    return (
        <Create 
            isLoading={formLoading}
            footerButtons={({ defaultButtons }) => (
                <></>
            )}
        >
            <ImageUploader label={'Image'} name={'image'} url={url} setUrl={setUrl} />

            <form onSubmit={handleSubmit}>
                <TextField
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label="Name"
                    name="name"
                    onChange={handleOnChange}
                />
                <TextField
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label="Invite a new Care Team Member"
                    name="member"
                    onChange={handleOnChange}
                />

                <Button type="submit" disabled={formLoading} variant="contained" startIcon={<SaveAsOutlined />}>
                    {formLoading && <div>Loading...</div>}
                    <span>Save</span>
                </Button>
            </form>
        </Create>
    );
};
