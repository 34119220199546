import { Stack } from "@mui/material"
import { Link } from "@pankod/refine-react-router-v6"

export const PublicFooter = () => {
    return (
        <>
        <Stack direction={{ xs: 'column', sm: 'row' }} style={{backgroundColor:'black', padding:20, color:'white'}}>
            <div style={{width:'80%'}}>
                <h1 style={{lineHeight:1}}>Contact
                <br />
                Help<span style={{color:'#f4a9d0'}}>Grandma</span></h1>
                <h4>Email: Info@helpgrandma.com</h4>
            </div>
            <div>
                <h1>Quick Links</h1>
                <ul>
                    <Link style={{color:'white'}} to="/login"><li>Login</li></Link>
                    <Link style={{color:'white'}} to="/register"><li>Register</li></Link>
                    <Link style={{color:'white'}} to="/start/project"><li>Start a Project</li></Link>
                    <Link style={{color:'white'}} to="/what-we-do"><li>What We Do</li></Link>
                    <Link style={{color:'white'}} to="/how-we-do-it"><li>How We Do It</li></Link>
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSf5N6gj5_acr6bBMc-AZ7wU69S_sjLmEkRGMDhyiXSWCLYmFA/viewform" target='_blank' style={{color:'white'}}><li>Provider Application</li></a>
                </ul>
            </div>
        </Stack>
        <Stack direction={{ xs: 'column', sm: 'row' }} style={{backgroundColor:'black', padding:20, color:'white'}}>
            <div style={{width:'80%'}}>
                <span>© Copyright 2023. HelpGrandma is a Registered Trademark.</span>
            </div>
            <div>
                <span>Privacy | Terms</span>
            </div>

        </Stack>
        </>
    )
}