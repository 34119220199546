import React, { useState, useEffect } from 'react';
import { useShow, useMany, HttpError } from "@pankod/refine-core";
import {
    Show,
    Typography,
    Stack,
    TextField,
    Button,
    Grid,
    LinearProgress,
    Paper,
    Tabs,
    Tab,
    Modal,
    Box,
    Card,
    CardContent,
    Alert,
    Checkbox,
    FormControlLabel,
    TextareaAutosize,
    EditButton,
    DeleteButton,
} from "@pankod/refine-mui";
import { CommentBox } from 'components/commentBox';
import axios from 'axios';
import { CheckCircleOutline, Done, FlagCircleOutlined, Share } from '@mui/icons-material';
import { LoggedInUser } from 'utils/auth.management';
import dayjs from 'dayjs';
import { Link } from '@pankod/refine-react-router-v6';
import {CopyToClipboard} from 'react-copy-to-clipboard';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

interface ITransactions {
    id: number;
    name: string;
    material: string;
    amount: string;
    created: string;
    project_id: string;
    status: string;
}

export const ProjectShow = () => {
    const { queryResult } = useShow();
    const { data, isLoading } = queryResult;
    const [donationAmount, setDonationAmount] = useState('0')
    const [modalStep1, setModalStep1] = useState(true)
    const [modalStep2, setModalStep2] = useState(false)
    const [modalStep3, setModalStep3] = useState(false)
    const [recurringStep1, setReccurringStep1] = useState(false)
    const [name, setName] = useState('')
    const [cardNumber, setCardNumber] = useState('')
    const [exp_date, setExpDate] = useState('')
    const [cvc, setCvc] = useState('')
    const [subscriptionResults, setSubResults] = useState<any>(null)
    const [teamRecord, setTeamRecord] = useState<any>([])
    const [endProjectModal, setEndProjectModal] = useState(false)
    const [goalReachedModal, setGoalReachedModal] = useState(false)
    const [recurringReminderOption, setRecurringReminderOption] = useState(false)
    const [recurringReminderForm, setRecurringReminderForm] = useState(false)
    const [email, setEmail] = useState('')
    const [cell_phone, setCellPhone] = useState('')

    const record = data?.data;
    
    const [tabValue, setTabValue] = useState('one')
    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue)
    }

    const [openModal, setOpenModal] = React.useState(false);
    const handleOpen = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);

    const [paymentURL, setPaymentURL] = useState(null)

    const sendPaymentRequest = async (localamount:any) => {
        try {
            const results = await axios.post(process.env.REACT_APP_API+'/transactions', { project:record?.title, amount:localamount, status:"Pending", project_id:record?.id})
            setPaymentURL(results.data.session.url)
        } catch (error) {
            console.log(error)
        }
    }

    const sendSubscriptionPaymentRequest = async () => {
        try {
            const results = await axios.post(process.env.REACT_APP_API+'/transactions/stripe/subscriptions', { number:cardNumber, exp_month:exp_date.slice(0,2), exp_year:exp_date.slice(3,6), cvc, unit_amount:donationAmount, name, project_id:record?.id})
            setSubResults(results.data)
        } catch (error) {
            console.log(error)
        }
    }

    const createInvoice =  () => {
        if(donationAmount.includes('.')){
            let localamount = donationAmount.replace(".","")
            sendPaymentRequest(localamount)
        } else {
            let localamount = donationAmount+"00"
            sendPaymentRequest(localamount)
        }
    }

    const { data:transactionsData, isLoading:loadingData, isError } = useMany<ITransactions, HttpError>({
        resource: "transactions",
        ids:[],
    });

    const { data:team_data, isLoading:team_isLoading, isError:team_isError } = useMany<HttpError>({
        resource: "teams",
        ids:[],
    });

    useEffect(() => {
        let localTeams:any[] = []
        team_data?.data.filter(item => item.projects.includes(record?.id) && localTeams.push(item))
        setTeamRecord(localTeams)
    }, [team_data])
    

    //const products = data?.data ?? [];
    const get_percentage = (current_amount:number, goal:number) => (current_amount / goal ) * 100;

    const total_amount_raised = (record:any) => {

        let subtotal:number[] = []
        transactionsData?.data.filter(item => item.status === "paid" && item.project_id === record.id && subtotal.push(Number(item.amount)*.01))

        let total = subtotal.reduce((partialSum, a) => partialSum + a, 0)
        
        return total

    }

    const goal_percentage = (record:any) => {

        let percentage = get_percentage(total_amount_raised(record), record?.target_funding_goal)
        
        if (percentage > 100) {
            return 100
        } else {
            return percentage
        }
    }

    const updateProject = async(id:any, url:String, parameters:Object) => {
        try {
            await axios.post(process.env.REACT_APP_API+"/projects/"+url+"/"+id, parameters)
        } catch (error) {
            console.log(error)
        }
    }
    
    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (isError) {
        return <div>Something went wrong!</div>;
    }

    return (
        <Show 
            canDelete={LoggedInUser().id === record?.founder}
            canEdit={LoggedInUser().id === record?.founder}
            isLoading={isLoading}
            title={<Typography variant="h5">{record?.title}</Typography>}
            headerButtons={({ defaultButtons }) => (
                <>
                    <EditButton />
                    <DeleteButton />
                    <Button color='primary' onClick={() => setEndProjectModal(true)}><Done />End Project</Button>
                    <Modal
                        open={endProjectModal}
                        onClose={() => setEndProjectModal(false)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                        Which alert would you like to send your Care Team members?
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <Button 
                            style={{margin:5}} 
                            color='success' 
                            variant='contained'
                            onClick={() => {
                                updateProject(record?.id, 'project_ended', {ended:"Successful", project_name:record?.title, project_id:record?.id, care_team:record?.care_team})
                            }}
                        >Successful</Button>
                        <Button 
                            style={{margin:5}} 
                            color='error' 
                            variant='contained'
                            onClick={() => {
                                updateProject(record?.id, 'project_ended', {ended:"Not Successful", project_name:record?.title, project_id:record?.id, care_team:record?.care_team})
                            }}
                        >Thank You for your Donation!</Button>
                        </Typography>
                    </Box>
                    </Modal>
                    <Button color='primary' onClick={() => setGoalReachedModal(true)}><FlagCircleOutlined />Funding Goal Reached</Button>
                    <Modal
                        open={goalReachedModal}
                        onClose={() => setGoalReachedModal(false)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                        Would you like to Alert your Care Team that the Funding Goal has been reached?
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <Button 
                            color='success' 
                            variant='contained'
                            onClick={() => {
                                updateProject(record?.id, 'goal_reached', {goal_reached:true, project_name:record?.title, project_id:record?.id, care_team:record?.care_team})
                            }}>Funding Goal has been reached!</Button>
                        </Typography>
                    </Box>
                    </Modal>
                </>
            )}
        >
            <Tabs
                value={tabValue}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
            >
                <Tab
                    value="one"
                    label="Home"
                />
                <Tab value="two" label="Donors" />
                <Tab value="three" label="Coordination" />
            </Tabs>

            <Stack 
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 1, sm: 2, md: 4 }} 
                style={{width:'fit-content', margin:'auto'}}
            >
                {tabValue === 'one' &&
                <>
                    <Grid>
                        <img
                            style={{maxWidth:"100%", width:650, borderRadius:10 }}
                            src={record?.campaign_photo}
                        />
                    </Grid>
                </>
                }
                {tabValue === 'two' &&
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} style={{height:'fit-content'}}>
                    {transactionsData?.data.map((item, index) =>
                        item.project_id ===  record?.id && 
                        item.status === "paid" &&
                        <Grid item key={index}>     
                        <Card>
                            <CardContent>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                {item.name}
                                </Typography>
                                <Typography variant="h5" component="div"> 
                                ${Number(item.amount)*.01}
                                </Typography>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                {item.created}
                                </Typography>
                            </CardContent>
                        </Card>
                        </Grid>
                    )}
                </Grid>
                }
                {tabValue === 'three' &&
                    <CommentBox details={{resource:'project', resource_id:record?.id}} />
                }
                <div style={{minWidth:250, maxWidth:350}}>
                    <h2>
                        <Share /> 
                        Project Share Link
                        <TextareaAutosize id="standard-basic" value={process.env.REACT_APP_HOST+"/project/"+record?.id} style={{width:'100%'}} />
                        <CopyToClipboard text={process.env.REACT_APP_HOST+'/project/'+record?.id}
                            onCopy={() => alert('copied to clipboard')}
                        >
                            <Button variant='contained'>Copy to clipboard</Button>
                        </CopyToClipboard>
                        <Button variant='contained' style={{margin:5}}><Link style={{textDecoration:'none', color:'white'}} to={'/project/'+record?.id} target="_blank">Public View</Link></Button>
                    </h2>
                    <Paper elevation={3} style={{padding:15}}>
                        <LinearProgress style={{height:10}} variant="determinate" value={goal_percentage(record)} />
                        Raised of ${record?.target_funding_goal}
                        
                        {record?.target_funding_goal < total_amount_raised(record) &&
                            <Alert severity="success">This Project has reached it’s Funding Goal! <a href="https://www.appsheet.com/start/431ab169-cfc3-40e4-9713-4a3526fea20a?platform=desktop" target='_blank'>Have you chosen a Provider for your loved one's project?</a></Alert>
                        }
                        {record?.ended && record?.ended === 'Not Successful' && <Alert severity="error">Thank you for your donation! This project has ended.</Alert>}
                        {record?.ended && record?.ended === 'Successful' && <Alert severity="success">This project — was successful!</Alert>}
                        {!record?.fee_paid && <Alert severity="error">This project is not active — you must make the first $12 donation to make this project visible to others. "Go to EDIT Project" to Donate $12 and publish your Project</Alert>}
                        <br />
                        <h1>${total_amount_raised(record).toFixed(2)}</h1>
                        
                        <iframe
                            width="100%"
                            height="200"
                            src={'https://www.youtube.com/embed/'+record?.project_video.replace('https://youtu.be/', '')}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Embedded video"
                        />

                        <h3>{record?.category}
                        <br />
                        {record?.subcategory}
                        </h3>

                        <span>
                            <span style={{fontSize:30, fontWeight:'bold'}}>My Story</span>
                            <br />
                            {record?.story}
                        </span>

                        <br />
                        <br />
                        <span>
                            <span style={{fontSize:20, fontWeight:'bold'}}>Are you able to give?</span>
                            <br />
                            Goal: ${record?.target_funding_goal}
                        </span>
                        <br />

                        <br />
                        <span style={{fontSize:20, fontWeight:'bold'}}>Project Due Date:</span>
                        <br />
                        {dayjs(record?.funding_target_date.slice(0, 10)).format('MMMM DD, YYYY')}
                        <br />
                        <br />
                        <i>Any gift amount is appreciated!</i>
                        
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} style={{marginTop:20}}>
                            <Grid item xs={3}>
                            <img src={teamRecord[0]?.image} style={{width:75, height:75, borderRadius:50}} />
                            </Grid>
                            <Grid item xs={6} style={{marginTop:-10}}>
                                <h3>Care Team:
                                    <br />
                                <Link to={"/teams/show/"+teamRecord[0]?._id}>{teamRecord[0]?.name}</Link></h3>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper elevation={3} style={{padding:15, marginTop:20}}>
                        <Stack>
                            <Button variant="contained" style={{ margin:10 }} onClick={handleOpen}>Make A Donation</Button>
                        </Stack>
                        <Modal
                            open={openModal}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                                <Box sx={style}>
                                    {modalStep1 &&
                                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                        <h3>Will Donations be made for an Assisted Living, Nursing Home or monthly Housing expense?</h3>
                                        {["Yes", "No"].map((item, index) =>
                                            <Button 
                                                onClick={() => {
                                                    setModalStep1(false)
                                                    setModalStep2(true)
                                                }}
                                                key={index}
                                            >
                                                {item}
                                            </Button>
                                        )}
                                    </Typography>
                                    }
                                    {modalStep2 &&
                                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                        <h3>Would you like to set up recurring payments?</h3>
                                        {["Yes", "No"].map((item, index) =>
                                            <Button 
                                                onClick={() => {
                                                    if(item === "Yes"){
                                                        setModalStep2(false)
                                                        setReccurringStep1(true)
                                                    } else {
                                                        setModalStep2(false)
                                                        setRecurringReminderOption(true)
                                                    }
                                                }}
                                                key={index}
                                            >
                                                {item}
                                            </Button>
                                        )}
                                    </Typography>
                                    }
                                    {recurringStep1 &&
                                        <>
                                        {!subscriptionResults &&
                                        <>
                                            {[{label:'Name', name:"name", ['value']:name, ['setValue']:setName},
                                            {label:'Recurring Monthly Payment Amount', name:"amount", ['value']:donationAmount, ['setValue']:setDonationAmount},
                                            {label:'Card Number', name:"card_number", ['value']:cardNumber, ['setValue']:setCardNumber},
                                            {label:'Exp Date', name:"exp_date", ['value']:exp_date, ['setValue']:setExpDate},
                                            {label:'CVC', name:"cvc", ['value']:cvc, ['setValue']:setCvc},
                                            ].map((item, index) =>
                                                <TextField
                                                    margin="normal"
                                                    fullWidth
                                                    InputLabelProps={{ shrink: true }}
                                                    type="text"
                                                    label={item.label}
                                                    name={item.name}
                                                    onChange={(e) => item.setValue(e.target.value)}
                                                    value={item.value}
                                                />
                                            )}
                                            <Button variant='contained' onClick={sendSubscriptionPaymentRequest}>Submit Subscription Payment</Button>
                                        </>
                                        }
                                        {subscriptionResults &&
                                            <>
                                                <p style={{display:'flex', alignItems:'center'}}><CheckCircleOutline color="primary" /> Your donation has been accepted and your subscription has started.</p>
                                                {subscriptionResults && 
                                                <>
                                                    <p>Subscription ID: {subscriptionResults.id}</p>
                                                </>
                                                }
                                            </>
                                        }
                                        </>
                                    }
                                    {recurringReminderOption &&
                                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                        <h3>Would you like to set up recurring payment reminders?</h3>
                                        {["Yes", "No"].map((item, index) =>
                                            <Button 
                                                onClick={() => {
                                                    if(item === "Yes"){
                                                        setRecurringReminderOption(false)
                                                        setRecurringReminderForm(true)
                                                    } else {
                                                        setRecurringReminderOption(false)
                                                        setModalStep3(true)
                                                    }
                                                }}
                                                key={index}
                                            >
                                                {item}
                                            </Button>
                                        )}
                                    </Typography>
                                    }
                                    {recurringReminderForm &&
                                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                        <h3>Would you like to set up recurring payments?</h3>
                                        {[{label:"Email", setValue:setEmail}, {label:"Cell Phone", setValue:setCellPhone}].map((item, index) =>
                                            <TextField 
                                                id="outlined-basic" 
                                                label={item.label} 
                                                variant="outlined"
                                                key={index}
                                                onChange={(e) => item.setValue(e.target.value)}
                                            />
                                        )}
                                        
                                        <Button 
                                            style={{margin:5}}
                                            variant="contained"
                                            onClick={() => {
                                                setRecurringReminderForm(false)
                                                setModalStep3(true)
                                                axios.post(process.env.REACT_APP_API+"/monthly_donation_reminder", {email, reminder:"Don't forget your monthly Donation to "+record?.title, cell_phone})
                                            }}
                                        >
                                            Set up reminder
                                        </Button>
                                    </Typography>
                                    }
                                    {modalStep3 &&
                                        <>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                type="text"
                                                label="Donation Amount"
                                                name="donation_amount"
                                                onChange={(e) => setDonationAmount(e.target.value)}
                                                value={donationAmount}
                                            />
                                            <Button 
                                                onClick={() => {
                                                    setModalStep3(false)
                                                    createInvoice()
                                                }} 
                                                variant="contained"
                                            >
                                                Make Donation
                                            </Button>
                                        </>
                                    }
                                    
                                    {paymentURL &&
                                    <>
                                        <b>You will be temporarily directed to “Stripe”, a trusted credit card processor to complete your Donation. For your security, Helpgrandma does not store Credit Card information.</b>
                                        <br />
                                        <FormControlLabel onClick={() => window.open(paymentURL, "_blank")} control={<Checkbox />} label="That's ok!" />
                                    </>
                                    }
                                </Box>
                        </Modal>
                    </Paper>
                </div>
            </Stack>
        </Show>
    );
};
