import React, { useState } from 'react'
import { LayoutProps, useMany, HttpError } from "@pankod/refine-core";
import { ListItemText, Sider, Header as DefaultHeader, Box, Card, CardContent, Typography, List, Tabs, Tab } from "@pankod/refine-mui";
import { CustomSider } from 'components/CustomSider';
import { LoggedInUser } from 'utils/auth.management';

interface INotify {
    id: number;
    notify: string;
    account: string;
    created: string;
    updated: string;
}

export const UserNotificationPage: React.FC<LayoutProps> = ({
    Header,
    Footer,
    OffLayoutArea,
    children,
}) =>  {
    const HeaderToRender = Header ?? DefaultHeader;

    //const [ids, setIds] = useState([1, 2, 3]);

    const [tabValue, setTabValue] = React.useState('one');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };

    const { data, isLoading, isError } = useMany<INotify, HttpError>({
        resource: "notifications",
        ids: ['63f8258bcbb95168ccfd177e'],
    });

    const notifications = data?.data ?? [];

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (isError) {
        return <div>Something went wrong!</div>;
    }

    return (
        <Box display="flex" flexDirection="row">
            <CustomSider />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    minHeight: "100vh",
                }}
            >
                <HeaderToRender />
                <Box
                    component="main"
                    sx={{
                        p: { xs: 1, md: 2, lg: 3 },
                        flexGrow: 1,
                        bgcolor: "background.default",
                    }}
                >
                    <List
                        title={<Typography variant="h5">Notifications</Typography>}
                    >
                        <Tabs
                            value={tabValue}
                            onChange={handleChange}
                            textColor="secondary"
                            indicatorColor="secondary"
                            aria-label="secondary tabs example"
                        >
                            <Tab value="one" label="All" />
                            <Tab value="two" label="Yours" />
                        </Tabs>
                        {tabValue === 'one' &&
                        notifications?.map(item => 
                        !item.account &&
                            <Card sx={{ minWidth: 275 }}>
                                <CardContent>
                                    <Typography variant="h5" component="div">
                                        {item.notify}
                                    </Typography>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        <span>Created: {item.created}
                                        <br />{item.updated && 'Updated:'+item.updated }</span>
                                    </Typography>
                                </CardContent>
                            </Card>
                        )}
                        {tabValue === 'two' &&
                        notifications?.map(item => 
                        item.account === LoggedInUser().id &&
                            <Card sx={{ minWidth: 275 }}>
                                <CardContent>
                                    <Typography variant="h5" component="div">
                                        {item.notify}
                                    </Typography>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        <span>Created: {item.created}
                                        <br />{item.updated && 'Updated:'+item.updated }</span>
                                    </Typography>
                                </CardContent>
                            </Card>
                        )}
                    </List>
                </Box>
                {Footer && <Footer />}
            </Box>
            {OffLayoutArea && <OffLayoutArea />}
        </Box>
    );
};