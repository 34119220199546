import {
    TextField,
    Autocomplete,
    Button,
} from "@pankod/refine-mui";
import { useState, useEffect } from "react";
import { useCustomMutation, useMany } from "@pankod/refine-core";
import { Delete } from "@mui/icons-material";
import { Box, Container, Grid } from "@mui/material";
import { ProjectList } from "./ProjectList";

export const TeamAddProject = (props:any) => {

    const { team_id, projects } = props

    const { data: allprojects } = useMany({
        resource: "projects",
        ids: [],
    });
    
    const { mutate } = useCustomMutation();

    const [projectsField, setProjectsField] = useState<any>([])
    const [allRecords, setAllRecords] = useState<any>([])

    let apiUrl = process.env.REACT_APP_API

    const handleMember = (x:any) => {
        setProjectsField([...projectsField, ...projects, x.id])
    }

    const handleSubmit = () => {

        mutate({
            url: `${apiUrl}/teams/${team_id}`,
            method: "patch",
            values: {
                projects: projectsField,
            },
        });

        projectsField?.filter((item:any) => 
            mutate({
                url: `${apiUrl}/projects/${item}`,
                method: "patch",
                values: {
                    care_team: team_id,
                },
            })
        )

        setTimeout(() => { 
            window.location.reload()
        }, 3000);
    };

    const handleDelete = (id:any) => {
        let array = projects
        const index = array.indexOf(id);
        if (index > -1) { // only splice array when item is found
            let deletedItem = array.splice(index, 1); // 2nd parameter means remove one item only
            let newAr = projects.filter((item:any) => item !== deletedItem)
            setProjectsField(newAr)
        }
        
        var answer = window.confirm('Do you want to remove this member from the team?');
        if (answer) {
            mutate({
                url: `${apiUrl}/teams/${team_id}`,
                method: "patch",
                values: {
                    projects,
                },
            });

            mutate({
                url: `${apiUrl}/projects/${id}`,
                method: "patch",
                values: {
                    care_team: null,
                },
            })
        }
    }

    useEffect(() => {
      let localAllRecords:any[] = []
      if(projects.length > 0){
        allprojects?.data.filter((item:any) => !projects?.includes(item.id) && !item.care_team && localAllRecords.push({...item, ...{label:item.title}}))
      } else {
        allprojects?.data.filter((item:any) => !item.care_team && localAllRecords.push({...item, ...{label:item.title}}))
      }
      setAllRecords(localAllRecords)
    }, [allprojects, projects])

    return (
        <Container>

            <Box
                component="form"
                sx={{
                '& > :not(style)': { m: 1, width: '45ch' },
                }}
                noValidate
                autoComplete="off"
            >
                <Autocomplete
                    disablePortal
                    
                    onChange={(_, value) => {
                        handleMember(value)
                    }}
                    id="combo-box-demo"
                    options={allRecords}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField  {...params} label="Projects" />}
                    fullWidth
                />
                <Button variant="contained" onClick={handleSubmit}>Add Project</Button>
            </Box>


            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>                
                {

                    projects?.map((item:any, index:number) =>
                    <Grid item xs={2} sm={4} md={4} key={index}>
                        <Delete onClick={() => handleDelete(item)} style={{color:'red', marginBottom:-20, zIndex:100, cursor:'pointer', position:'relative'}} />
                        <ProjectList id={item} edit_status={true} />
                    </Grid>
                    )
                
                }
            </Grid>
        </Container>
    );
};
