import { Box, Button, ButtonGroup, Card, CardContent, Container, LinearProgress, Modal, Typography } from "@mui/material"
import { DeleteButton } from "@pankod/refine-mui"
import { Link } from "@pankod/refine-react-router-v6";
import dayjs from 'dayjs';
import { useMany, HttpError } from "@pankod/refine-core";
import { LoggedInUser } from "utils/auth.management";
import { useState } from "react";
import { style } from "utils/styles.custom";

export const ProjectPreview = (data:any) => {

    const [readMore, setReadMore] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)

    const { _ } = data

    const { data: transactions_data, isLoading: transactions_isLoading, isError: transactions_isError } = useMany<HttpError>({
        resource: "transactions",
        ids: [],
    });

    const get_percentage = (current_amount:number, goal:number) => (current_amount / goal ) * 100;

    const total_amount_raised = (record:any) => {

        let subtotal:number[] = []
        transactions_data?.data.filter(item => item.status === "paid" && item.project_id === record.id && subtotal.push(Number(item.amount)*.01))

        let total = subtotal.reduce((partialSum, a) => partialSum + a, 0)
        
        return total

    }

    const goal_percentage = (record:any) => {

        let percentage = get_percentage(total_amount_raised(record), record?.target_funding_goal)
        
        if (percentage > 100) {
            return 100
        } else {
            return percentage
        }
    }

    function isDateBeforeToday(date:any) {
        return new Date(date).valueOf() > new Date().valueOf();
    }

    return (
        <>
            <Card style={{height:'92%', marginBottom:10}}>
                <img src={_.campaign_photo} style={{width:'100%', marginBottom:-10}} />
                <LinearProgress variant="determinate" value={goal_percentage(_)} style={{height:30}} />
                <p style={{paddingLeft:5, zIndex:1, position:'relative', marginTop:-28, color:'black', fontWeight:900}}>${total_amount_raised(_).toFixed(2)}</p>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                    {_.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                    {readMore ? _.story : _.story.slice(0, 80)+" ..." }
                    <Button 
                        size="small" 
                        variant="contained" 
                        style={{color:'white'}}
                        onClick={() => {
                            if(readMore){
                                setReadMore(false)
                            } else {
                                setReadMore(true)
                            }
                        }}
                    >
                        {readMore ?  "Read Less" : "Read More"}
                    </Button>
                    </Typography>
                    <br />
                    <Typography gutterBottom variant="h5" component="div">
                    Target:
                    <br />
                    ${_.target_funding_goal}
                    </Typography>
                    <br />
                    <Typography>
                        Thank you for your kindness.
                        {isDateBeforeToday(_.funding_target_date) ?
                        <p >Project End Date: {dayjs(_.funding_target_date.slice(0, 10)).format('MMMM DD, YYYY')}</p>
                        :
                        <p style={{color:'red'}}>Ended.</p>
                        }
                    </Typography>
                </CardContent>
            </Card>
            <Container style={{display:'flex', justifyContent:'center', padding:0}}>
                    <ButtonGroup>
                        <Button size="small" variant="contained">
                            <Link to={'/projects/show/'+_.id} target={"_blank"} style={{textDecoration:'none', color:'white'}}>
                                View
                            </Link>
                        </Button>
                        {LoggedInUser().id === _?.founder &&
                        <>
                            <Button size="small" variant="contained">
                                <Modal
                                    open={deleteModal}
                                    onClose={() => setDeleteModal(false)}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box sx={style}>
                                        <Typography id="modal-modal-title" variant="h6" component="h2">
                                        Are you sure you want to delete your project?
                                        </Typography>
                                        <Button
                                            onClick={() => setDeleteModal(false)}
                                            style={{marginBottom:10}}
                                        >
                                            No, keep my project
                                        </Button>
                                        <DeleteButton startIcon={<></>} recordItemId={_.id} >
                                            Delete my project
                                        </DeleteButton>
                                    </Box>
                                </Modal>
                                <span 
                                    onClick={() => {
                                        setDeleteModal(true)
                                    }}
                                    style={{color:'white'}}
                                >Delete
                                </span>
                            </Button>
                            <Button size="small" variant="contained">
                                <Link to={'/projects/edit/'+_.id} style={{textDecoration:'none', color:'white'}}>
                                    Edit
                                </Link>
                            </Button>
                        </>
                        }
                    </ButtonGroup>

            </Container>
        </>
    )
}