import { Button, Grid, Menu, MenuItem, Stack } from "@mui/material"
import { Link } from "@pankod/refine-react-router-v6"
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { LoggedInUser } from "utils/auth.management"
import {
    useLogout,
} from "@pankod/refine-core";
import { useEffect, useState } from "react";
import { PasswordBlocker } from "./passwordBlock";

export const PublicMenu = () => {
    const { mutate: mutateLogout } = useLogout();
    const [mobile, setMobile] = useState(false)

    useEffect(() => {
        window.addEventListener('resize', () => {
            const myWidth  = window.innerWidth;
            if(myWidth < 970){
            setMobile(true)
            } else {
            setMobile(false)
            }
        })

        if(window.innerWidth < 970){
            setMobile(true)
        } else {
            setMobile(false)
        }
    },[window])

    
    return (
        <>
            <PasswordBlocker />
            <Grid item xs={4}>
                <img src='/images/help-grandma-logo-1.png' style={{maxWidth:275, width:'100%'}} />
            </Grid>
            <Grid item xs={8} style={{ alignItems:"center", justifyContent:"right", display:'flex'}}>
                {!mobile ?
                <Stack direction="row" spacing={2} style={{color:'white', alignItems:'center'}}>
                    <Link to="/home" style={{color:'white', textDecoration:'none'}}><h3>Home</h3></Link>
                    <Link to="/what-we-do" style={{color:'white', textDecoration:'none'}}><h3>What We Do</h3></Link>
                    <Link to="/how-we-do-it" style={{color:'white', textDecoration:'none'}}><h3>How We Do It</h3></Link>
                    <Link to="/contact-us" style={{color:'white', textDecoration:'none'}}><h3>Contact</h3></Link>
                    {!LoggedInUser() ?
                    <Link to="/login" style={{color:'white', textDecoration:'none'}}><h3>Login</h3></Link>
                    :
                    <>
                    <Link to="/" style={{color:'white', textDecoration:'none'}}><h3>Dashboard</h3></Link>
                    <h3 onClick={() => mutateLogout()} style={{color:'white', textDecoration:'none', cursor:'pointer'}}>Logout</h3>
                    </>
                    }
                </Stack>
                :
                <PopupState variant="popover" popupId="demo-popup-menu">
                    {(PopupState) => (
                        <>
                            <Button variant="contained" style={{backgroundColor:'#f4a9d0'}} {...bindTrigger(PopupState)}>
                                Menu
                            </Button>
                            <Menu {...bindMenu(PopupState)}>
                                <MenuItem onClick={PopupState.close}><Link to="/home" style={{textDecoration:'none'}}>Home</Link></MenuItem>
                                <MenuItem onClick={PopupState.close}><Link to="/what-we-do" style={{textDecoration:'none'}}>What We Do</Link></MenuItem>
                                <MenuItem onClick={PopupState.close}><Link to="/how-we-do-it" style={{textDecoration:'none'}}>How We Do It</Link></MenuItem>
                                <MenuItem onClick={PopupState.close}><Link to="/contact-us" style={{textDecoration:'none'}}>Contact</Link></MenuItem>
                                {!LoggedInUser() ?
                                <MenuItem onClick={PopupState.close}><Link to="/login" style={{textDecoration:'none'}}>Login</Link></MenuItem>
                                :
                                <>
                                <Link to="/login" style={{textDecoration:'none'}}><MenuItem onClick={PopupState.close}>Dashboard</MenuItem></Link>
                                <MenuItem onClick={PopupState.close}><Link to="/login" style={{textDecoration:'none'}}>Logout</Link></MenuItem>
                                </>
                                }
                            </Menu>
                        </>
                    )}
                </PopupState>
            }
            </Grid>
        </>
    )
}